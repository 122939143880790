import {useRef,useState} from "react";
import jwt from 'jwt-decode'
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import constants from "../../common/constants";
import commonFunctions from "../../common/commonFunctions";
import ApiUser from "../../common/apiUser";
import ErrorList from "../form/ErrorList";
import ProcessingMessage from "../form/ProcessingMessage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import Auth from '../../auth/useAuth';
import ApiBiggie from "../../common/apiBiggie";
import log from '../../common/logFunctions';

export default function Login(){

    const inputUsername = useRef();
    const inputPwd = useRef();
    const [processing, setProcessing] = useState(false);
    const [errorMessages, setErrorMessage] = useState([]);    
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleLoginClick = async () => {
        const checkUsername = inputUsername.current.value;
        const checkPwd = inputPwd.current.value;
       
        setProcessing(true);
        var valid = true;
        var errMsg = [];

        if (!commonFunctions.emailValidation(checkUsername)){
            valid = false;            
            errMsg.push("Invalid email address format.");
        }

        if (valid){

            const userCredentials = {
                username: checkUsername,
                password: checkPwd
            }

            //check JWT Group
            const apiUser = new ApiUser();
            var successfullyLoggedin = false;

            try{

                const response = await apiUser.userLogin(userCredentials);

                Auth.credentialsTolocalStorage(response, constants.loggedInStatus.loggedIn);     
                successfullyLoggedin = true;   

                log.logInfo("LoginForm.handleLoginClick",`User logged in ${checkUsername}`)        
            }catch(error){
                    
                if (error.response.status === 403) {
                    //place your reentry code
                    if (error.response.data.code === "UserNotConfirmedException"){
                        localStorage.setItem(constants.storage.loggedIn, constants.loggedInStatus.confirm);
                        window.location = `/register/confirm/${checkUsername}`;
                    }else{ 
                        errMsg.push(error.response.data.message);                                                                        
                    }
                }else{
                    log.logError("LoginForm.handleLoginClick",JSON.stringify(error));
                }
            }

            if (successfullyLoggedin){
                const sid = searchParams.get('sid');

                if (sid != null){
                    const apiBiggie = new ApiBiggie();

                    const context = {
                        'updateAction': 'ownership'
                    }
                    
                    try{
                        await apiBiggie.ScenariosUpdate(sid, context);
                    }catch(err){
                        console.error(JSON.stringify(err));
                    }

                }
                setProcessing(false);
                window.location.href = "../dashboard";  
            }            
            
        }
        setProcessing(false);
        setErrorMessage(errMsg);
    }

    const clearErr = () => {        
        setErrorMessage([]);
    }

    
    
    return(
        <div className="container p-2">
            
            
            <ErrorList errors={errorMessages}/>
            <ProcessingMessage processing={processing}/>
            <div className="p-1 mb-2">
            <b>Enter your username and password below.</b>
            </div>
            <div className="field">
                <p className="control has-icons-left">
                    <input ref={inputUsername} type="email" onFocus={clearErr} className="input is-medium" placeholder="Email"/>
                    <span class="icon is-left">
                        <FontAwesomeIcon icon={faEnvelope}/>
                    </span>
                
                </p>
            </div>
            <div className="field">
                <p className="control has-icons-left">
                    <input ref={inputPwd} type="password" onFocus={clearErr} className="input is-medium" placeholder="Password"/>
                    <span class="icon is-left">
                        <FontAwesomeIcon icon={faLock}/>
                    </span>
                </p>
                
            </div>
            <div>
                <button className="button is-primary" onClick={handleLoginClick}>Login</button>
            </div>
            
            <div className="p-1 mt-2">
                Not registered? <Link to="/register">Click here</Link>
            </div>
            <div className="p-1 mt-2">
                Forgot your password? <Link to="/resetpassword">Click here</Link>
            </div>
        </div>        
    );
    

}