import * as axios from "axios";
import apiConfig from "../config/apiConfig";
import constants from "./constants";

export default class ApiUser {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = apiConfig.apiBaseUrl.userApi;
    this.api_key = apiConfig.apiBaseUrl.userApiKey;
  }

  init = () => {
    this.api_token = localStorage.getItem(constants.storage.jwt);

    let headers = {
      'Accept': 'application/json',
      'x-api-key': this.api_key
    };

    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
    });

    return this.client;
  };

  userLoginAnon = () => {
      return this.init().post("/users/anon");
  }

  userCognitoRegister = (data) => {
   return this.init().post("/biggieusers/register",data);
  }

  userCognitoRegisterConfirm= (data) => {
    return this.init().post("/biggieusers/register/confirm", data);
  }

  userCognitoRegisterCodeResend = (data) => {
    return this.init().post("/biggieusers/register/coderesend", data);
  }

  userCognitoLogin = (data) => {
    return this.init().post("/biggieusers/login", data);
  }

  userCognitoAssignGroup = () => {
  return this.init().post("/biggieusers/group/assign");
  }

  userCognitoValidateToken = (data) => {
    return this.init().post("biggieusers/token/validate", data);
  } 

  userCognitoRefreshToken = (data) => {
    return this.init().post("biggieusers/token/refresh", data);
  } 

  userCognitoForgotPasswordCode = (data) => {
    return this.init().post("biggieusers/password/forgot/code", data);
  } 

  userCognitoForgotPasswordReset = (data) => {
    return this.init().post("biggieusers/password/forgot/reset", data);
  } 

  userCognitoDeleteAccount = (id) => {
    return this.init().delete(`biggieusers/delete/${id}`);
  }

  // Lambda Function
  userLogin = (data) => {
    return this.init().post("/sessions", data);
  }
  
  // Lambda Function
  userRegister = (data) => {
    return this.init().post("/users",data);
  }

  // Lambda Function
  userRegisterConfirm= (data) => {
    return this.init().post("/users/confirm", data);
  }

  // Lambda Function
  userRegisterCodeResend = (data) => {
    return this.init().post("/users/resend", data);
  }

  // Lambda
  userForgotPasswordCode = (data) => {
    return this.init().post("/credentials/sendcode", data);
  } 

  // Lambda
  userForgotPasswordReset = (data) => {
    return this.init().put("/credentials", data);
  } 
  
  // Lambda
  userValidateToken = (data) => {
    return this.init().post("/tokens/validate", data);
  } 

  // Lambda
  userRefreshToken = (data) => {
    return this.init().post("/tokens/refresh", data);
  }

  // Lambda
  userDeleteAccount = (uid) => {
    return this.init().delete(`/users/${uid}`);
  }

  

/* from example
https://medium.com/swlh/react-api-call-best-practice-c78e14a7e150

  getUserList = (params) => {
    return this.init().get("/users", { params: params });
  };

  addNewUser = (data) => {
    return this.init().post("/users", data);
  };
*/
}