import React, {  useEffect, useState } from "react";
import StackedChart from "../chart/StackedChart";
import LineChart from "../chart/LineChart";
import RankingList from "../scenarios/RankingList";
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import ApiBiggie from "../../common/apiBiggie";
import PageNav from "../form/PageNav";
import TitleEditable from "../form/TitleEditable";
import ErrorList from "../form/ErrorList";
import reportHelper from '../../common/reportFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrophy} from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom';
import constants from "../../common/constants";
import LoadingPage from "../../parts/LoadingPage";
import log from "../../common/apiLog";



export default function ChoiceReport(){
    const [scenario, setScenario] = useState();
    const [chartData, setChartData] = useState();
    const [chartHistoryData, setChartHistoryData] = useState();
    const [errorMessages, setErrorMessage] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasHistory, setHasHistory] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let {id} = useParams();
    const calc = searchParams.get('calc');

    useEffect(async () => {                
    
        const apiBiggie = new ApiBiggie();

        if (calc == 1){
            try{
                await apiBiggie.initialize();
                await apiBiggie.ScenarioClassificationsCreate(id);
            }catch(err){            
                //console.error(JSON.stringify(err));
                log.logError("ChoiceReport.useEffect",`Creating classifiction ${JSON.stringify(err)}`);
            }
        }

        try{            
            const result = await apiBiggie.ScenarioGetById(id);
            setScenario(result.data);
            setChartData(reportHelper.chartDatasetsFromScenario(result.data));

            // get the results history in the background
            try{
                const classifications = await apiBiggie.ScenarioClassificationsList(id);
                const historyPresent = (classifications.data.length > result.data.scenario_option_ids.length);
                setHasHistory(historyPresent);

                const history = reportHelper.chartDatasetsFromClassifications(classifications.data);            
                setChartHistoryData(history);

                
            }catch(err){
                console.error(JSON.stringify(err));
            }
        }catch(err){            
            console.error(JSON.stringify(err));
        }
       
        setIsLoaded(true);

    }, [])

    const handleBackClick = () => {
        navigate(`/assess/${id}`);
    }

    if (!isLoaded) {
        return <div><LoadingPage/>.</div>;
    } else {

        return(
            <>
              
            <div className="notification">Please find your <b>Thought Report</b> below. Based on your ratings, here is how your choices are ranked. If you would like to refine, click the links above.
            {
                (localStorage.getItem(constants.storage.loggedIn) === constants.loggedInStatus.loggedInAnon)
                &&
                (<>&nbsp;If you would like to save or share your report, please <Link to={`/register?sid=${id}`}>register</Link> or <Link to={`/login?sid=${id}`}>login</Link>.</>)
            }            
            </div>   
            <ErrorList errors={errorMessages}/> 
            {(scenario) && 
            (
                <>
                <div className="mb-2">
                <TitleEditable title={scenario.scenario_desc} id={id} setErrorMessage={setErrorMessage}/>
                </div>
                <div className="box">                                            
                    <RankingList rankingdata={scenario.option_ranking_ids} />
                </div>
                </>
            )
            }
            {(chartData) && (
                //relative; height:40vh; width:80vw
                //style={{height: "300px"}}
             
                <>
                <div className="notification">Below is a breakdown of how your scores were calculated.</div>
                <div>
                    <StackedChart title={'Score breakdown'} labels={chartData.labels} data={chartData.datasets}/>                
                </div>                
                </>
            )}
            {(hasHistory) && (
                <>
                    <div className="notification mt-4">Below is how your assessment has changed over time.</div>
                    <div>
                        <LineChart title={'Ranking History'} labels={chartHistoryData.labels} data={chartHistoryData.datasets} xtitle={'Previous Assessments'} ytitle={'Previous Ranking'}/>
                    </div>
                </>
            )}   
            <PageNav backClick={handleBackClick}/>
            </>
        );
    }

}
