import React from "react";
import RegisterForm from "../../components/account/RegisterForm";
import BoxedLayout from "../../parts/layout/BoxedLayout";

export default function Register(){

    return (
        <BoxedLayout content=
        {
            <>
            <h1 className="title">Register</h1>            
            <RegisterForm/>
            </>
        }
        />
    );
}