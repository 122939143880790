import Reac from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListOl,  faChartBar, faGavel, faStar} from '@fortawesome/free-solid-svg-icons'

import { useParams, Link } from 'react-router-dom';


export default function BreadcrumbNav (props){
    let {id} = useParams();

    return (
        <>
        <nav className="breadcrumb is-right mt-4">
        <ul>
            <li className={(props.page == 'choices') ? 'is-active' : ''}>
                <Link to={'/choices/' + id}><small>Choices</small></Link>
            </li>
            <li className={(props.page == 'criteria') ? 'is-active' : ''}>
                <Link to={'/criteria/' + id}><small>Criteria</small></Link>
            </li>
            <li className={(props.page == 'assess') ? 'is-active' : ''}>
                <Link to={'/assess/' + id}><small>Assess</small></Link>
            </li>
            <li className={(props.page == 'result') ? 'is-active' : ''}>
                <Link to={`/result/${id}?calc=1` }><small>Report</small></Link>
            </li>
        </ul>
        </nav>
        
        </>
    )

}