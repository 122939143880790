import react, {useState, useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faArrowLeft,faCheck} from '@fortawesome/free-solid-svg-icons';
import useAuth from "../../auth/useAuth";
import ApiUser from "../../common/apiUser";
import ApiBiggie from "../../common/apiBiggie";
import ErrorList from "../form/ErrorList";
import {Link} from 'react-router-dom';
import constants from "../../common/constants";
import commonFunctions from "../../common/commonFunctions";


export default function AccountForm(){
    const [errorMessages, setErrorMessage] = useState([]);
    const [completed, setCompleted] = useState(false);

    const handleDeleteAccountClick = async () => {
        const apiUser = new ApiUser();
        const apiBiggie = new ApiBiggie();


        try{
            apiUser.userDeleteAccount(useAuth.getIdTokenAttribute("cognito:username"));
        }catch(err){
            setErrorMessage([JSON.stringify(err)]);
            console.error(JSON.stringify(err));
        }

        const updateContext = {
            'update_action' : 'delete-all'
        }

        try{
            await apiBiggie.ScenariosUpdate(constants.scenario.noId, updateContext);
        }catch(err){
            setErrorMessage([constants.error.generalMessage]);
            console.error(JSON.stringify(err));
        }

        commonFunctions.clearSessionStorage();
        setCompleted(true);

    }

   

    return(
    <>
    <div className="items-left-aligned">
    <ErrorList errors={errorMessages}/>

    {(completed) ?
    (
        <>
        <div>
        Your account has been deleted. 
        </div>        
        <hr/>
        <div>
        <Link to='/' className="button">Home</Link>
        </div>
        </>
    ):
    (
        <>
        <hr/>        
           
            <div className="p-2"><b>Username:</b> {useAuth.getIdTokenAttribute("email")}</div>   
            <div className="tag"><small>This is also your contact email.</small></div>       
        <hr/>        
            <div className="mt-4 ml-2">
                <Link to="/resetpassword" className="button is-link">Reset password</Link>
            </div>     
        <hr/>
        <div className="p-2">
            <button className="button is-danger" onClick={handleDeleteAccountClick}>Delete account</button>
        </div>    
        
        </>
    )
    }
    </div>
    </>
    );
}