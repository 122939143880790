import 'bulma/css/bulma.min.css';
import React, {useState, useEffect} from 'react';
import {Route, Routes,Navigate, Link} from 'react-router-dom';
import Recall from './pages/Recall';
import Choices from './pages/Choices';
import Criteria from './pages/Criteria';
import Assess from './pages/Assess';
import Navbar from './parts/navigation/Navbar';
import Home from './pages/Home';
import Register from './pages/account/Register';
import Login from './pages/account/Login';
import Dashboard from './pages/Dashboard';
import RegisterConfirm from './pages/account/RegisterConfirm';
import ResetPassword from './pages/account/ResetPassword';
import ResultSummary from './pages/ResultSummary';
import Problem from './pages/Problem';
import Advice from './pages/Advice';
import Auth from './auth/useAuth';
import constants from './common/constants';
import AccountDetails from './pages/account/AccountDetails';
import TermsAndConditions from './pages/TermsAndConditions';
import ScrollToTop from './parts/SrollToTop';
import CookieMessage from './parts/CookieMessage';


function App() {
  const [authenticated, setAuth] = useState(localStorage.getItem(constants.storage.loggedIn) == constants.loggedInStatus.loggedIn);
  const [confirmRequired, setConfirm] = useState(localStorage.getItem(constants.storage.loggedIn) == constants.loggedInStatus.confirm);
  
  useEffect(async () => {    
    
    try{
      //await Auth.isAuthenticated(); 
      const loginStatus = await Auth.loginStatus();   
      setAuth(loginStatus === constants.loggedInStatus.loggedIn);
      setConfirm(loginStatus === constants.loggedInStatus.confirm);                
    }catch(error){
      console.log(error);
      setAuth(false);
      setConfirm(false);
    }
  }, []);
  
  return (
    <div className="App">      
        <Navbar authenticated={authenticated}/>
        <ScrollToTop />
        <div className="container">
        <Routes>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="/recall" exact element={<Recall />}></Route>
          <Route path="/register" exact element={<Register />}></Route>
          <Route path="/login" exact element={<Login />}></Route>
          <Route path="/resetpassword" exact element={<ResetPassword />}></Route>
          {authenticated ?
          (
            <>
            <Route path="/dashboard" exact  element={<Dashboard />}></Route>            
            <Route path="/account" element={<AccountDetails />}></Route>            
            </>
          )
          :
          (
            <>
            <Route path="/dashboard" exact  element={<Login />}></Route>
            </>
          )
          }
          {confirmRequired ?
          (
            <>
            <Route path="/register/confirm/:email" element={<RegisterConfirm/>}></Route>
            </>
          )
          :
          (
            <>
            <Route path="/register/confirm" exact element={<Login/>}></Route>
            </>
          )
          }
          <Route path="/choices/:id" exact element={<Choices />}></Route>
          <Route path="/criteria/:id" exact element={<Criteria />}></Route>
          <Route path="/assess/:id" exact element={<Assess />}></Route>
          <Route path="/result/:id" exact element={<ResultSummary />}></Route>
          <Route path="/advice/:id" exact element={<Advice />}></Route>
          <Route path="/problem" exact element={<Problem />}></Route>
          <Route path="/termsandconditions" exact element={<TermsAndConditions />}></Route>
          <Route path="" element={<Navigate to="/" />} />
        </Routes>
        </div>   
        <hr/>
        <div className='has-text-centered mb-6'>
          <small><Link to="/termsandconditions">Terms & Conditions</Link></small>
        </div>   
        <CookieMessage/>      
    </div>
  );
}

export default App;
