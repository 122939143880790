import * as axios from "axios";
import apiConfig from "../config/apiConfig";
import constants from "./constants";

export default class ApiLog {

  constructor() {
   
    this.api_token = null;
    this.client = null;
    this.api_url = apiConfig.apiBaseUrl.logApi;
    this.api_key = apiConfig.apiBaseUrl.userApiKey;
    
  }

  init = () => {
   
    this.api_token = localStorage.getItem(constants.storage.jwt);

    let headers = {
      'Accept': 'application/json',
      'x-api-key': this.api_key
    };

    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
    });

    return this.client;
  };
  
  // Lambda Function

  logsCreate = (data) => {
    return this.init().post("/logs",data);
  }

}