
import React from "react";
import CriteriaList from "../components/criteria/CriteriaList";
import BreadcrumbNav from "../components/form/BreadcrumbNav";

export default function Choices(){

    return (
        <div className="container  is-fluid has-text-left">             
            <BreadcrumbNav page='criteria'/>
            <div className="notification"><b>Step 2 of 3:</b> Review, refine and weight the criteria below. The criteria is what you consider important when making a choice. How important you believe the criteria is will will weight your results.</div>                  
            <CriteriaList/>
        </div>
    )

}