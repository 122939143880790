import * as axios from "axios";
import apiConfig from "../config/apiConfig";
import constants from "./constants";
import useAuth from "../auth/useAuth";

export default class ApiBiggie {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = apiConfig.apiBaseUrl.biggieApi;
  }

  init = () => {
    this.api_token = localStorage.getItem(constants.storage.idToken);

    let headers = {
      "Accept": "application/json",
      'Access-Control-Allow-Headers' : 'Content-Type',
      'Access-Control-Allow-Origin': '*', // Allow from anywhere 
      'Access-Control-Allow-Methods': '*'
    };

    if (this.api_token) {
      headers.Authorization = `${this.api_token}`;
    }

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
    });

   

    return this.client;
  };

  async initialize(){
    await useAuth.loginStatus();
  } 

  /*
  scenarioNew = (data) => {
    return this.init().post("/scenarios", data);
  }

  scenarioNewByCategory = (id,data) => {
    return this.init().post("/scenarios/createbycategory/" + id, data);
  }

  scenarioGet = (id) => {
    return this.init().get("/scenarios/" + id);
  }

  optionUpdate = (id, data) => {
    return this.init().put("/options/" + id, data);
  }

  scenarioOptionNew = (id, data) => {
    return this.init().put("/scenarios/option/" + id, data);
  }

  scenarioOptionDelete = (id, data) => {
   
    return this.init().put("scenarios/optionremove/" + id, data);
  }

  scenarioDimensionNew = (id, data) => {
    return this.init().put("scenarios/dimension/" + id, data)
  }

  scenarioDimensionDelete = (id, data) => {
    return this.init().put("scenarios/dimensionremove/" + id, data);
  }
*/

  ScenariosGet = (id) => {
    return this.init().get(`/scenarios/${id}`);
  }
  //Resource Oriented
  ScenariosCreate = (data) => {
    return this.init().post("/scenarios", data);
  } 

  //updates ownership
  ScenariosUpdate = (id, data) => {
    return this.init().put(`/scenarios/${id}`, data);
  } 
  
  //Resource Oriented
  ScenarioGetById = (id) => {
    return this.init().get(`/scenarios/${id}`);
  }

  //Resource Oriented
  ScenarioList = () => {
    return this.init().get(`/scenarios`);
  }

  //Resource Oriented
  ScenarioOptionsCreate = (id, data) => {
    return this.init().post(`/scenarios/${id}/options`, data);
  }

  //Resource Oriented
  ScenarioOptionsUpdate = (id, oid, data) => {
    return this.init().put(`/scenarios/${id}/options/${oid}`, data);
  }

  //Resource Oriented
  ScenarioOptionsDelete = (id, oid) => {
    return this.init().delete(`/scenarios/${id}/options/${oid}`);
  }

  //Resource Oriented
  ScenarioDimensionsCreate = (id, data) => {
    return this.init().post(`/scenarios/${id}/dimensions`, data);
  }

  //Resource Oriented
  ScenarioDimensionsDelete = (id, did) => {
    return this.init().delete(`/scenarios/${id}/dimensions/${did}`);
  }

  //Resource Oriented
  ScenarioDimensionsUpdate = (id, did, data) => {
    return this.init().put(`/scenarios/${id}/dimensions/${did}`, data);
  }
  
  ScenarioScoresCreate = (id, data) => {
    return this.init().post(`/scenarios/${id}/scores`, data);
  }

  ScenarioClassificationsCreate = (id) => {
    return this.init().post(`/scenarios/${id}/classifications`);
  }

  ScenarioClassificationsList = (id) => {
    return this.init().get(`/scenarios/${id}/classifications`);
  }

}