import React, {  useEffect, useState } from "react";
import {useParams, useNavigate} from 'react-router-dom';
import ApiBiggie from "../../common/apiBiggie";
import InputAddable from "../form/InputAddable";
import InputDeletable from "../form/InputDeletable";
import TitleEditable from "../form/TitleEditable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar} from '@fortawesome/free-solid-svg-icons'
import LikertScale from "../form/LikertScale";
import ErrorList from "../form/ErrorList";
import ProcessingMessage from "../form/ProcessingMessage";
import PageNav from "../form/PageNav";
import LoadingPage from "../../parts/LoadingPage";
import constants from "../../common/constants";
import log from '../../common/logFunctions';

export default function CriteriaList(){

    const [errorMessages, setErrorMessage] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [title, setTitle] = useState();
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    let {id} = useParams();
    const apiBiggie = new ApiBiggie();
    
    useEffect(async () => {                
  
        try{
            await apiBiggie.initialize();            
            const result = await apiBiggie.ScenarioGetById(id);
            
            setItems(result.data.scenario_dimension_ids);
            setTitle(result.data.scenario_desc);
            setIsLoaded(true);
            
        }catch(err){            
          setErrorMessage([constants.error.generalMessage]);
          log.logError("CriteriaList.useEffect",JSON.stringify(err));
        }

        
    }, [])

  
    const addCriteria = async (val) => {
      setErrorMessage([]);
      if (val != null && val.length > 0){  
          const newDimension = {
                  dim_weight: 5,
                  dim_desc: val
              }
        try{
            setProcessing(true);      
            const response = await apiBiggie.ScenarioDimensionsCreate(id,newDimension);
            setItems([response.data,...items]);
        }catch(err){            
          setErrorMessage([constants.error.generalMessage]);
          log.logError("CriteriaList.addCriteria",JSON.stringify(err));
        }
        setProcessing(false); 
      }else{
        setErrorMessage(['The value entered is empty']); 
      }
    }

    const deleteCriteria = async (dimensionId) => {
      setErrorMessage([]);
      try{
        apiBiggie.ScenarioDimensionsDelete(id,dimensionId);
      }catch(err){
        setErrorMessage([constants.error.generalMessage]);
        log.logError("CriteriaList.deleteCriteria",JSON.stringify(err));
      }

      const revisedItemList = [];
      items.forEach(dId => {
        if (dId._id != dimensionId){
          revisedItemList.push(dId);
        }
       
      });
      setItems(revisedItemList);
    }

    const updateWeight = (dimensionId, count) => {
      setErrorMessage([]);
      
      console.log(`Dimension ${dimensionId} : weight ${count}`);

      const weight = (count > 0) ? count : 0;

      const dim = {
        "dim_weight": weight
      }

      try{        
        apiBiggie.ScenarioDimensionsUpdate(id,dimensionId,dim);
      }catch(err){
        setErrorMessage([constants.error.generalMessage]);
        log.logError("CriteriaList.updateWeight",JSON.stringify(err));
      }

    }

    const handleNextClick = () => {
      navigate(`/assess/${id}`);
    }

    const handleBackClick = () => {
      navigate(`/choices/${id}`);
    }
  
    if (!isLoaded) {
      return <div><LoadingPage/></div>;
    } else {
      return (
        <>
        <div className="box">
          <ErrorList errors={errorMessages}/>   
          <ProcessingMessage processing={processing}/>   
          <div className="title ml-2">
            <TitleEditable title={title} id={id} setErrorMessage={setErrorMessage}/>
          </div>          
          <div className="level-item level-left">             
            <div>                             
              <InputAddable addValue={addCriteria} navRefresh={'/choices/' + id}/>                      
            </div>            
            <div className="level-item level-left">   
            <FontAwesomeIcon icon={faStar} color="#FFCC33"></FontAwesomeIcon>&nbsp;(<small>importance</small>)
            </div>
          </div>
          <hr/>    
          {items.map(criteria => (
          <>
              <div key={criteria._id}>  
                <div>
                  <InputDeletable id={criteria._id} desc={criteria.dim_desc} deleteValue={deleteCriteria} />
                </div>
                <div>
                  <LikertScale number={10} default={(criteria.dim_weight)} preferredIcon={faStar} scaleId={criteria._id} updateScale={updateWeight} />                   
                </div>
              </div>
              <hr/>                     
          </>
          ))}        
        </div>
        <PageNav nextClick={handleNextClick} backClick={handleBackClick}/>
        </>
      );
    }
}