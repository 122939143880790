import React, {useState, useRef} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faArrowLeft,faCheck} from '@fortawesome/free-solid-svg-icons'
import ApiBiggie from "../../common/apiBiggie";


export default function TitleEditable(props)
{
    const [editing, setEditing] = useState(false);
    const [title, setTitle] = useState(props.title);
    const inputTitle = useRef();



    const handleUpdateTitle = async () => {
        if (inputTitle.current!= null && inputTitle.current.value.length > 0){            
            setTitle(inputTitle.current.value);    
            
            const apiBiggie = new ApiBiggie();

            const titleChange = {
                'update_action': 'title',
                'scenario_desc' : inputTitle.current.value
            }
            
            try{
                apiBiggie.ScenariosUpdate(props.id, titleChange);
                setEditing(false);
                props.setErrorMessage([]);
            }catch(err){
                console.error(JSON.stringify(err));
            }

        }else{
            props.setErrorMessage(['No value entered'])
        }
    }
    

    const handleCancel = () => {
        setTitle(props.title);
        setEditing(false);
        props.setErrorMessage([]);
    }

    return (
        <>
        
        {editing ? 
            (
                <>            
                <div className="level-item level-left">
                    <button  onClick={() => {handleCancel()}} className="button mr-2" ><FontAwesomeIcon icon={faArrowLeft} size="sm"/></button>  
                    
                    <input ref={inputTitle} defaultValue={title} className="input  mr-2" size="5"/>
                    <button  className="button is-danger" onClick={() => handleUpdateTitle()} ><FontAwesomeIcon icon={faCheck}  size="sm"/></button>  
                </div>
                </>
            )
        :
        (
        <div className="level-item level-left">
            <h4 className="title is-4">{title}</h4>
            <a onClick={() => setEditing(true)} ><FontAwesomeIcon className="ml-2" icon={faPencilAlt} size="xs"/></a>
        </div>
        )
        }    
        </>
    )

}