import react from "react";

export default function FloatingBottomBar (props) {

    return(
        <>
        <div className="bottom-bar" data-bottombar="cookieMessage">
            <div className="bottom-bar__content">
                <div className="bottom-bar__text">
                <small>{props.message}</small>
                </div>
                <button onClick={props.handleClick} className="button" type="button" data-bottombar-close="cookieMessage">{props.buttontext}</button>
            </div>
        </div>
        </>
    )
}