import React from "react";
import NumberRecall from "../components/Recall";


export default function Recall(){

    return (
        <div>
            <NumberRecall count={5}/>
        </div>
    )

}