import React from "react";
import LoginForm from "../../components/account/LoginForm";
import BoxedLayout from "../../parts/layout/BoxedLayout";
export default function Register(){

    return (
        <BoxedLayout content=
        {
            <>
                <h1 className="title is-3">Login</h1>
                <LoginForm/>
            </>
        }
        />
    );
}