import React from "react";


export default function ErrorList(props) {
    
    return (
            (props.errors.length > 0) && (
            <div className="notification is-danger">
            {props.errors.map(err => (
                <li key={err}>
                {err}
                </li>
            ))}      
            </div>
            )    
         
    );

}