import React, {useState} from "react";
import TitleEditable from "../form/TitleEditable";
import helper from "../../common/commonFunctions";
import {Link} from 'react-router-dom';
import RankingList from "./RankingList";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faChartBar, faListOl} from '@fortawesome/free-solid-svg-icons';
import ApiBiggie from "../../common/apiBiggie";

export default function ScenarioSummary(props){
    const [rankingVisible, setRankingVisible] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const handleDeleteScenario = () => {
        props.deleteScenario(props.scenario._id);
    }

    return(
        <>
        <div>
            <div>
                {(deleting) ? (
                <div className="notification">
                    <div className="p-2">
                    <b>Please confirm the deletion: '{props.scenario.scenario_desc}'</b>
                    </div>
                    <div>
                        <a onClick={handleDeleteScenario} className="button is-danger mr-2">Delete</a>
                        <a onClick={() => setDeleting(false)} className="button p-2">Cancel</a>
                    </div>                        
                </div>
                ) : (
                
                <div className="has-text-right">
                    <button className="delete" onClick={() => setDeleting(true)}/>
                </div>         
                )
                }
                <div className="p-1">
                    <div className="columns">
                        <div className="column is-6">
                        <TitleEditable id={props.scenario._id} title={props.scenario.scenario_desc}/>     
                        </div>
                    </div>
                </div>                
                <div className="p-1">
                    <b>
                    {props.scenario.option_ranking_ids.length > 0 ? 
                    (<> 
                    <FontAwesomeIcon icon={faTrophy} color="gold" className="mr-2" />                    
                    <Link to={'/choices/' +  props.scenario._id}>{props.scenario.option_ranking_ids[0].scenario_option_description}</Link>
                    </>)
                    : 
                    <Link to={'/choices/' +  props.scenario._id}>Click to complete</Link>
                    }
                    </b>
                </div>
                <div className="p-1">
                    <small>Created&nbsp;{helper.formatDate(props.scenario.date_created)}</small>
                </div>                
                <div className="level-item level-left mt-2 mb-2">
                {(props.scenario.option_ranking_ids.length > 0) 
                &&
                (
                    <>
                    
                    <a className="button is-link mr-2" onClick={() => (setRankingVisible(!rankingVisible))}><FontAwesomeIcon icon={faListOl}/>&nbsp;Rankings</a> 
                    
                    <Link className="button is-link" to={`/result/${props.scenario._id}`}><FontAwesomeIcon icon={faChartBar} /> &nbsp;Report</Link>
                    
                    </>
                )}                           
                </div>

                {(props.scenario.option_ranking_ids.length > 0 && rankingVisible) &&
                (
                    <div className="p-4 b-border" style={{border : "1px solid whitesmoke"}}>                    
                        <RankingList rankingdata={props.scenario.option_ranking_ids}/>                                        
                    </div>
                )}                                                         
            </div>
        </div>
        </>
    )
}