import React, {useRef, useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faTimes} from '@fortawesome/free-solid-svg-icons'

export default function InputDeletable(props){
    const inputEl = useRef(null);
    const [deleted,deleteState] = useState(false);
    
   

    

    const handleDelete = () => {
        deleteState(true);
        props.deleteValue(props.id);
    }

    return (
    <div>
        {(!deleted) && (      
            <>    
            <div className="level-item level-left">                         
                    <p className="ml-3">                        
                        
                        <button  onClick={handleDelete} className="button" aria-label="delete"><FontAwesomeIcon icon={faTimes} /></button>                    
                    </p>                    
                    <div>
                    <p>      
                        <span className="ml-5 mr-3">
                            {props.desc}                
                        </span>              
                    </p>                   
                    </div>               
            </div>            
            </>
        )}       
    <div>
        
    </div>    
    </div>);
}