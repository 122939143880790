import react from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock} from '@fortawesome/free-solid-svg-icons'

export default function LoadingPage(){

    return (<>    
    <section class="hero is-fullheight mt-4">        
        <div>
            <article class="message is-warning">
                <div className="message-body">            
                    <FontAwesomeIcon icon={faClock} /> Loading...                        
                </div>
            </article>           
        </div>        
    </section>
    
    </>)

}