import react from "react";
import BoxedLayout from "../../parts/layout/BoxedLayout";
import AccountForm from "../../components/account/AccountForm";

export default function AccountDetails() {

    return(
        <BoxedLayout content=
        {
            <>
            <h4 className="title is-4">Account Details</h4>
            <AccountForm/>
            </>
        }/>
    )

}