import React from "react";
import ConfirmationForm from "../../components/account/ConfirmationForm";
import BoxedLayout from "../../parts/layout/BoxedLayout";

export default function RegisterConfirm(){

    return (        
        <BoxedLayout content=
        {
        <>
            <h1 className="title">Registration Confirmation</h1>
            <ConfirmationForm/>
        </>
        }/>
    );
}