import React, { useRef, useState } from "react";

export default function RecallAttempt(props) {
    const inputEl = useRef(null);
    const [correct, setCorrect] = useState("");

    const handleClick = () => {
        if (inputEl.current.value === props.numbers){
            setCorrect("Correct");
        }else{
            setCorrect("Sorry");
        }
    };
      
    return (
        <div>
            <div>{props.numbers}</div>
            <input className="input is-normal" ref={inputEl} type="text"></input>
            <button className="button" onClick={handleClick}>Guess</button>
            <div>{correct}</div>
         </div>
    )

}