import react from "react";

export default function GMTLogo(props){

    return (
        <>
        <div className="level-left">
        <span><strong className={"title has-text-link has-text-weight-bold logo-font is-" + props.size }>get</strong>
        <strong className={"title has-text-primary has-text-weight-bold logo-font is-" + props.size}>my</strong>
        <strong className={"title has-text-link has-text-weight-bold logo-font is-" + props.size}>thinking.</strong>      
        </span>
        </div>
        </>
    )
}