import React, {  useEffect, useState } from "react";
import ApiBiggie from "../../common/apiBiggie";
import ScenarioSummary from "./ScenarioSummary";
import ChoiceNew from "../choice/ChoiceNew";
import constants from "../../common/constants";
import ProcessingMessage from "../form/ProcessingMessage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import LoadingPage from "../../parts/LoadingPage";

export default function ScenarioList () {
    const [errorMessages, setErrorMessage] = useState([]);
    const [scenarios,setScenarios] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [processing, setProcessing] = useState(false);
    const apiBiggie = new ApiBiggie();

    useEffect(async () => {                
        try{  
            await apiBiggie.initialize();
            const result = await apiBiggie.ScenarioList();            
            setScenarios(result.data);            
            setIsLoaded(true);    
        }catch(err){            
            setErrorMessage([constants.error.generalMessage]);
        }
        
    }, [])

    const handleScenarioDelete = (id) => {

        const apiBiggie = new ApiBiggie();
        setProcessing(true);
        const deleteChange = {
            'update_action': 'delete'
        }
        
        try{
            apiBiggie.ScenariosUpdate(id, deleteChange);                        
        }catch(err){
            console.error(JSON.stringify(err));
        }

        const revisedItemList = [];
        scenarios.forEach(sId => {
          if (sId._id != id){
            revisedItemList.push(sId);
          }
         
        });
        setScenarios(revisedItemList);
        setProcessing(false);
    }

    

    if (!isLoaded) {
        return <div><LoadingPage/></div>;
    } else {
        return(<>
        <div className="mt-4">
        <ProcessingMessage processing={processing}/>
        </div>
        {(scenarios.length > 0) ?
        (
            <>
            
            <div class="box p-2" >
            <p class="notification is-link title is-4">
                My thinking
            </p>   
            {scenarios.map(scenario => (
                <>
                <div key={scenario._id} className="p-4" style={{borderBottom : "1px solid whitesmoke"}}>                    
                    <div >                                                                           
                        <ScenarioSummary scenario={scenario} deleteScenario={handleScenarioDelete}/>                                    
                    </div>                    
                </div>                
                </>
            ))}
            </div>
            </>
        ):(
            <>
            <div className="notification">
                You don't have any decisions yet. 
            </div>
            <ChoiceNew color={'is-link'} linkname={"Click here to get started!"} category={constants.biggie.category.career}/>
            </>
        )
        }
        </>)
    }

}