import React, {  useEffect, useState } from "react";
import {useParams, useNavigate} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import ApiBiggie from "../../common/apiBiggie";
import LikertScale from "../form/LikertScale";
import PageNav from "../form/PageNav";
import ErrorList from "../form/ErrorList";
import { faStar, faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingPage from "../../parts/LoadingPage";
import log from '../../common/logFunctions';

export default function AssessList(props) {

    const [scenario, setScenario] = useState();
    const [errorMessages, setErrorMessage] = useState([]);
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);    

    let {id} = useParams();

    useEffect(async () => {                
        
        const apiBiggie = new ApiBiggie();

        try{            
            const result = await apiBiggie.ScenarioGetById(id);
            setScenario(result.data);
        }catch(err){            
            log.logError("AssessList.useEffect",JSON.stringify(err));
        }
        setIsLoaded(true);
        
    }, [])

    const updateScore = (dimOptid, score) => {
        console.log(`${dimOptid} = ${score}`);
        const scoreVals = dimOptid.split('_');

        const scenarioScore = {
            'scenario_id': id,
            'scenario_option_id' : scoreVals[0],
            'scenario_option_parent_id' : (scoreVals[1] === 'null' ? '' : scoreVals[1]),
            'scenario_dimension_id' : scoreVals[2],
            'scenario_option_parent_id' : (scoreVals[3] === 'null' ? '' : scoreVals[3]),
            'rating' : score
        }

        const apiBiggie = new ApiBiggie();

        try{                        
            apiBiggie.ScenarioScoresCreate(id, scenarioScore);
        }catch(err){            
            log.logError("AssessList.updateScore",JSON.stringify(err));
        }
    }
    
    const scoreLookup = (optId, dimId) => {
        var defaultRating = 5;
        
        try{
            for (var i=0; i < scenario.scenario_score_ids.length; i++){
                if (scenario.scenario_score_ids[i].scenario_option_id == optId &&
                    scenario.scenario_score_ids[i].scenario_dimension_id == dimId
                    )
                {
                    defaultRating = scenario.scenario_score_ids[i].rating;
                }
            }
        }catch(err){
            log.logError("AssessList.scoreLookup",JSON.stringify(err));
        }

        return defaultRating;

    }

    const handleNextClick = () => {
        navigate(`/result/${id}?calc=1`);
    }

    const handleBackClick = () => {
        navigate(`/criteria/${id}`);
    }

    const dimIndex = (dim) => {
        var index = 1;
        for (var i=0; i < scenario.scenario_dimension_ids.length; i++){
            if (scenario.scenario_dimension_ids[i]._id === dim){
                index = i+1;
            }
        }
        return index;
    }

    const getAnchorId = (dimId) => {        
        return "next" + dimIndex(dimId);
    }

    const getNextAnchorId = (dimId) => {
        return "#next" + (dimIndex(dimId) + 1);
    }

    const getPrevAnchorId = (dimId) => {
        return "#next" + (dimIndex(dimId) - 1);
    }

    const getLastAnchorId = () => {
        return "next" + (scenario.scenario_dimension_ids.length + 1);
    }

    if (!isLoaded || scenario == null) {
        return <div><LoadingPage/>.</div>;
    } else {
        return (            
            <>
                <div className="notification"><b>Step 3 of 3:</b> There are {scenario.scenario_dimension_ids.length} sections for you to answer, this takes a little longer. You are rating your choices against your criteria. Once complete we will show your Thought Report.</div>
                {(scenario != null) && 
                (scenario.scenario_dimension_ids.map(dim => (                    
                    <div className="box">
                        <div key={dim._id} className="p-2" id={getAnchorId(dim._id)}>
                            
                            <div className="title is-5">{dimIndex(dim._id)}. When thinking about "{dim.dim_desc}"</div>   
                            
                            {scenario.scenario_option_ids.map(option => (
                                <>
                                <hr/>  
                                <div key={option._id}>
                                    <div>
                                        <p>
                                        Rate <b>"{option.opt_desc}"</b> for <b>"{dim.dim_desc}"</b>?
                                        </p>
                                    </div>
                                    <div>                                   
                                        <LikertScale number={10} default={() => scoreLookup(option._id, dim._id)} preferredIcon={faStar} scaleId={`${option._id}_${option.opt_parent_id}_${dim._id}_${dim.dim_parent_id}`} updateScale={updateScore} />
                                    </div>                             
                                </div> 
   
                                </>
                            ))}                                                   
                        </div>
                        <div className="level-item">
                            <span className="p-2"><HashLink smooth  to={getNextAnchorId(dim._id)}><FontAwesomeIcon icon={faChevronDown} size="lg" color="gray"/></HashLink></span>
                            <span className="p-2"><HashLink smooth  to={getPrevAnchorId(dim._id)}><FontAwesomeIcon icon={faChevronUp} size="lg" color="gray"/></HashLink></span>
                        </div>                        
                    </div>
                )))}  
                <div id={getLastAnchorId()}>
                <PageNav nextClick={handleNextClick} backClick={handleBackClick}/>
                </div>
                
            </>
        )
    }
}