import React, { useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faSmile} from '@fortawesome/free-solid-svg-icons'

const createArray = (length) => [...Array(length)];

function Star({ selected = false,preferredIcon=faSmile, onSelect}){
    return (        
        <>     
        <FontAwesomeIcon icon={preferredIcon} color={selected ? "#FFCC33": "grey" } onClick={onSelect} />        
        </>
    );
}

export default function LikertScale(props) {

    const [selectedStars,setSelectedStars] = useState(props.default);

    const scaleChange = (i) => {
        const setTo = i + 1;
        setSelectedStars(setTo);        
               //call back to parent
        props.updateScale(props.scaleId,setTo);
    }

    return(
        <>
        <div className="level-item level-left">
        {
        createArray(props.number).map((n,i) => (
            <span className="p-1" key={i}>
            <Star 
            key={i}             
            selected={selectedStars > i}  
            onSelect={() => scaleChange(i)}
            preferredIcon={props.preferredIcon}            
            />
            </span>
        ))}
        </div>                
        </>
    );

}