import React from "react";
import {useLocation} from 'react-router-dom';
import constants from "../../common/constants";
import logo from '../../images/gmt-bl_t.png' 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import commonFunctions from "../../common/commonFunctions";
import GMTLogo from "../GMTLogo";

function GetPath() {
    const location = useLocation();
    return location.pathname;
}

export default function Navbar(props){

    const [isActive, setisActive] = React.useState(false);    
    const path = GetPath();

    const logout = () => {
      commonFunctions.clearSessionStorage();
    }
    
    return (
      <nav className='navbar is-light' role='navigation' aria-label='main navigation'>
        <div className='navbar-brand'>
          <a href='/' className='navbar-item'>
          <GMTLogo size={4}/>
          </a>

          <a onClick={() => {
              setisActive(!isActive)
            }}
            role='button'
            className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
            aria-label='menu'
            aria-expanded='false'
            data-target='navbarBasicExample'
          >
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
          </a>
         
        </div>
        <div id='navbarBasicExample' className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
          <div className='navbar is-light'>
            <div className='navbar-item'>
              <a href='/' className={`navbar-item ${path === '/' ? 'button' : ''}`}>
                Home
              </a>
              {(props.authenticated) ?
                <>
                
                <a href='/dashboard' className={`navbar-item ${path === '/dashboard' ? 'button' : ''}`}>
                  My thinking
                </a>
                <a href="/account" className={`navbar-item ${path === '/account' ? 'button' : ''}`}>
                  Account
                </a>
                <a href='/dashboard' onClick={logout} className={`navbar-item ${path === '/login' ? 'button' : ''}`}>
                  Logout
                </a>
                </>
                :
                <>                
                <a href='/register' className={`navbar-item ${path === '/register' ? 'button' : ''}`}>
                  Register
                </a>
                <a href='/login' className={`navbar-item ${path === '/login' ? 'button' : ''}`}>
                  Login
                </a>
                </>
              }
            </div>
          </div>
        </div>
      </nav>
      
    )


}