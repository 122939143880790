import React, {useRef,useState} from "react";
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,faCheck} from '@fortawesome/free-solid-svg-icons';
import ErrorList from "../form/ErrorList";
import ProcessingMessage from "../form/ProcessingMessage";
import constants from "../../common/constants";
import ApiUser from "../../common/apiUser";
import commonFunctions from "../../common/commonFunctions"
import PasswordNew from "../form/PasswordNew";
import log from '../../common/logFunctions';

export default function RegisterForm(){

    const [errorMessages, setErrorMessage] = useState([]);
    const [validEmail, setValidEmail] = useState(false);
    const [passwordValue, setPasswordValue] = useState("");
    const [processing, setProcessing] = useState(false);
    const inputUsername = useRef();
    const navigate = useNavigate();
    
    const handleRegisterClick = () => {

        var errMsg = [];
        const emailVal = inputUsername.current.value;
        const emailOK = (commonFunctions.emailValidation(emailVal));
        setProcessing(true);
   
        if (!emailOK){
          errMsg.push("Invalid email format");          
        }

        //const pwdWeakRegex = new RegExp("(?=.{7,})"); // 7 length
        const pwdVal = passwordValue;//inputPwd.current.value;
        const pwdOK = commonFunctions.passwordValidation(pwdVal);
        if (!pwdOK){
          errMsg.push("Invalid password - minium of 7 characters");        
        }

        setErrorMessage(errMsg);

        if (emailOK && pwdOK){
          const newUsername = inputUsername.current.value;
          const newPwd = pwdVal;

          const userCredentials = {
              username: newUsername,
              password: newPwd
          }

          const apiUser = new ApiUser();
          
          apiUser.userRegister(userCredentials)
            .then(response => {
              localStorage.setItem(constants.storage.user, JSON.stringify(response.data.user));
              localStorage.setItem(constants.storage.loggedIn, constants.loggedInStatus.confirm);
              console.log("success " + response.data.token);     
              //navigate(`/register/confirm/${newUsername}`);
              log.logInfo("RegisterForm.handleRegisterClick",`New user registered - ${ newUsername}`);
              window.location = `/register/confirm/${newUsername}`;
            }).catch(error => {
              setErrorMessage([error.response.data.message]);
            });
        }
        setProcessing(false);

    }


    const clearErr = () => {
      setErrorMessage([]);
    }

    const validateEmail = () => {
      const emailVal = inputUsername.current.value;
      const isOK = (commonFunctions.emailValidation(emailVal));

      setValidEmail(isOK);

    }

    return (
      <>
      
        <div className="container p-2">                  
            <ErrorList errors={errorMessages}/>
            <ProcessingMessage processing={processing}/>
            <div className="p-1 mb-2">
            <b>Please fill in the registration form below.</b>
            </div>
            <div className="field">
                <p className="control has-icons-left has-icons-right">
                    <input ref={inputUsername} type="email" onChange={validateEmail} onFocus={clearErr} className="input is-medium" placeholder="Email"/>
                    <span class="icon is-left">
                        <FontAwesomeIcon icon={faEnvelope}/>
                    </span>
                    <span class="icon is-right">
                      {validEmail && (
                        <FontAwesomeIcon icon={faCheck} color="turquoise"/>
                      )}
                    </span>
                </p>
            </div>
            <div className="mb-2">
            <PasswordNew getPasswordVal={setPasswordValue} clearError={clearErr} />
            </div>
            <div className="mb-2 mt-4 notification">
            By clicking "Agree & Join", you agree to the {constants.site.sitename} <Link to="/termsandconditions">User Agreement, Privacy Policy, and Cookie Policy</Link>.
            </div>
            <div>
                <button className="button is-primary" onClick={handleRegisterClick}>Agree & Join</button>
            </div>
            <div className="p-1 mt-2">
                Already registered? <Link to="/login">Click here</Link>
            </div>
       </div>
       </>
    );

}






