import React, {useRef,useState} from "react";
import { useNavigate,  useParams } from 'react-router-dom';
import ErrorList from "../form/ErrorList";
import ApiUser from "../../common/apiUser";
import MessageList from "../form/MessageList";
import ProcessingMessage from "../form/ProcessingMessage";
import constants from "../../common/constants";

export default function RegisterForm(){
    const [errorMessages, setErrorMessage] = useState([]);
    const [messages, setMessage] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [confirmSuccess, setConfirmSuccess] = useState(false);
    
    const inputCode = useRef();
    let {email} = useParams();
    let navigate = useNavigate();

    const handleConfirmCodeClick = async () => {
        setProcessing(true);
        const confirmCode = inputCode.current.value;

        const userCredentials = {
            username: email,
            code: confirmCode
        }

        const apiUser = new ApiUser();

        await apiUser.userRegisterConfirm(userCredentials)
          .then(response => {            
            setConfirmSuccess(true);
            console.log(`Success confirming code - ${confirmCode} for  ${email} - ${response}`);     
          }).catch(error => {
            setErrorMessage([error.response.data.message]);
            console.log(error.response.data.message);
          });

        setProcessing(false);
    }

    const handleCodeResendClick = async () => {
        setErrorMessage([]);
        setProcessing(true);
        const userCredentials = {
            username: email
        }

        const apiUser = new ApiUser();
        setProcessing(true);

        await apiUser.userRegisterCodeResend(userCredentials)
        .then(response => {         
            setMessage(["A new confirmation code has been sent to your email address "]);
            console.log(`Success sending a confirmation code to ${email}`);     
        }).catch(error => {
            setErrorMessage([error.message]);
            console.log(error.message);
        });
        setProcessing(false);
    }

    const clearAllMessages = () => {
        setErrorMessage([]);
        setMessage([]);
    }

    const redirectToLogin = () => {
        var path = '/login';
        const scenarioId = localStorage.getItem(constants.storage.scenarioId);
        if (scenarioId !=null){
            path += `?sid=${scenarioId}`;
        }
        navigate(path);
    }

    return (
        <div className="container p-2">
          <ErrorList errors={errorMessages}/>
          <ProcessingMessage processing={processing}/>
          <MessageList messages={messages}/>
          {(!confirmSuccess) ? (
            <div>
                <div className="p-1">
                    <b>A confirmation email has been sent, please enter the pass code</b>
                </div>            
                <div  className="field">                
                    <input type="text" className="input is-medium" ref={inputCode} placeholder="Enter confirmation code"/>
                </div>            
                <div>
                    <button onClick={handleConfirmCodeClick} onFocus={clearAllMessages}  className="button is-primary">Confirm</button>
                </div>
                <div>
                    <hr/>
                </div>
                <div className="p-1">
                    <b>Didn't receive a code or your code has expired?</b>
                </div>
                <div className="mt-2">
                    <button onClick={handleCodeResendClick} className="button">Resend</button>
                </div>
            </div>
            ) : (
            <div className="box">                               
                <div className="p-2"><b>Success! Your account has been activated.</b></div>
                <div className="p-2">Please login with your username and password.</div>
                <div className="mt-2">
                    <button className="button is-primary" onClick={redirectToLogin}>Login</button>
                </div>
            </div>            
            )}
        </div>

    );

}






