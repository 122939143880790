import React from "react";

export default function PageNav(props) {
    


    return (
        <>
        <div className="mb-5">
        {props.backClick && 
        (        
         <button className="button is-link mr-2" onClick={props.backClick}>Back</button>
        )}
        {props.nextClick && 
        (
         <button className="button is-link" onClick={props.nextClick}>Next</button>
        )}
        </div>
        </>                                 
    );

}