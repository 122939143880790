module.exports = {
    storage: {
        idToken : "idtoken",
        accessToken : "accesstoken",
        refreshToken : "refreshtoken",
        expiry : "expiry",
        user: "user",
        scenarioId : "scenarioId",
        loggedIn : "lis",
        agreeCookies : "agreecookies"
    },
    apiContext: {
        user : "user",
        biggie : "biggie"
    },
    biggie: {
        category: {
            career: 1,
            lifestyle: 2,
            business:3,
            finance: 4,
            shopping: 5,
            education: 6,
            relationships: 7,
            jobchange: 8,
            other: 9
        }
    },
    loggedInStatus: {
        loggedOut: "0",
        loggedIn : "1",
        expired :"2",
        confirm : "3",
        loggedInAnon : "4"
    },
    anon : {
        username : "anonymous-user"
    },
    scenario : {
        noId : '-1'
    },
    site : {
        sitename : "Get My Thinking",
        siteurl : "www.getmythinking.com",
        siteemail : "info@getmythinking.com"
    },
    error : {
        generalMessage : "There was a problem, please try again"
    }

}