import react from "react";
import TsAndCs from "../components/account/TsAndCs";

export default function TermsAndConditions(){

    return(<>
        
        <div className="container  is-fluid has-text-left">   
                      
            <div>
                <TsAndCs/>    
            </div>                    
        </div>
    </>);


}