import React,{useEffect,useState} from "react";
import constants from "../../common/constants";
import ApiUser from "../../common/apiUser";
import ApiBiggie from "../../common/apiBiggie";
import { useNavigate } from 'react-router-dom';
import Auth from '../../auth/useAuth';
import ProcessingMessage from "../form/ProcessingMessage";

export default function ChoiceNew(props){
  const [category, setCategory] = useState(-1);
  const [processing, setProcessing] = useState(false); 
  const navigate = useNavigate();


    useEffect(() => {
      setCategory(props.category);
    }, []);
   
    const createChoiceClick = async (props) => {

          setProcessing(true);
          const apiBiggie = new ApiBiggie();
          
          await Auth.scenarioSession();

          const scenarioNew = {
              scenario_desc: "My exciting choice",
              meta_data: "",
              scenario_category_id: category,
              username: "",
              copy: true
          };

          await apiBiggie.initialize();
          apiBiggie.ScenariosCreate(scenarioNew)
          .then(response => {
            console.log(`success scenario id = ${response.data._id}`);
            localStorage.setItem(constants.storage.scenarioId,response.data._id);
            navigate(`/choices/${response.data._id}`);
          })
          .catch(error => {
            console.log(error);
          })
          
    };

    return (
        <>
          <ProcessingMessage processing={processing}/>
          <div className="level is-mobile">
              
              <button className={`button ${props.color}`} onClick={createChoiceClick}>{props.linkname}</button>
          </div>
        </>
    )
}