import React, {useState, useRef, useEffect} from "react";
import { useNavigate} from 'react-router-dom';
import ApiUser from "../../common/apiUser";
import commonFunctions from "../../common/commonFunctions"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope} from '@fortawesome/free-solid-svg-icons';
import PasswordNew from "../form/PasswordNew";
import ProcessingMessage from "../form/ProcessingMessage";
import ErrorList from "../form/ErrorList";
import MessageList from "../form/MessageList";
import log from '../../common/logFunctions';
import useAuth from "../../auth/useAuth";
import constants from "../../common/constants";



export default function ResetPasswordForm()
{
    const [messages, setMessage] = useState([]);
    const [errorMessages, setErrorMessage] = useState([]);
    const [passwordValue, setPasswordValue] = useState("");
    const [codeSent, setCodeSent] = useState(false);
    const [codeVerified, setCodeVerified] = useState(false);
    const [email, setEmail] = useState("");
    const [processing, setProcessing] = useState(false);
    const inputUsername = useRef();
    const inputCode = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        
        async function emailAvailable(){
            const loginStatus = await useAuth.loginStatus();   
            if(loginStatus === constants.loggedInStatus.loggedIn){            
                const username = useAuth.getUserId();
                setEmail(username);
            }
        }
        
        emailAvailable();

    },[])    

    const handleCodeResendClick = async () => {

        clearAllMessages();
        setProcessing(true);
        var errMsg = [];
        var ready = true;

        if (email === "" && inputUsername.current.value === ""){            
            errMsg.push("Please enter email address");            
            ready = false;            
        }

        if (ready){
            const emailVal = inputUsername.current.value;
            
            const userCredentials = {
                username: emailVal
            }

            const apiUser = new ApiUser();

            await apiUser.userForgotPasswordCode(userCredentials)
            .then(response => {     
                setCodeSent(true);        
                setEmail(emailVal);
                setMessage(["If registered, a new confirmation code has been sent to your email address "]);
                console.log(`Success sending a confirmation code to ${email}`);                              
            }).catch(error => {
                log.logError("ResetPasswordForm.handleCodeResendClick", error.response.data.message);
                //setErrorMessage([error.response.data.message]);
                console.log(error.response.data.message);
            });
            
        }else{
            setErrorMessage(errMsg);
        }
        setProcessing(false);
    }

    const handleReset = async () => {

        clearAllMessages();
        var errMsg = [];
        var ready = true;

        setProcessing(true);

        if (email === "" && inputUsername.current.value === ""){            
            errMsg.push("Please enter email address");            
            ready = false;            
        }else{
            if (email ===""){
                setEmail(inputUsername.current.value);
            }
        }
        
        if (inputCode.current.value === ""){
            errMsg.push("Please enter a code");
            ready = false;
        }

        if (!commonFunctions.passwordValidation(passwordValue)){
          errMsg.push("Invalid password - minium of 7 characters");        
          ready = false;
        }

        if (ready){

            const passwordReset = {
                username: email,
                password: passwordValue,
                code: inputCode.current.value
            }

            const apiUser = new ApiUser();

            await apiUser.userForgotPasswordReset(passwordReset)
            .then(response => {
                setCodeVerified(true);        
                setMessage(["Your password has been successfully changed, please login with your new password"]);    

            }).catch(error => {
                log.logError("ResetPasswordForm.handleReset",error.message);
                //setErrorMessage([error.message]);
            });
        }else{
            setErrorMessage(errMsg);
        }
        setProcessing(false);
    }

    const clearAllMessages = () => {
        setMessage([]);
        setErrorMessage([]);
    }

    const switchToResend = () => {
        setCodeSent(false);
        clearAllMessages();
    }

    const redirectToLogin = () => {
        navigate('/login');
    }

    return (
        <div>
            <ErrorList errors={errorMessages}/>
            <MessageList messages={messages}/>
            <ProcessingMessage processing={processing}/>
            {(!codeVerified) ? (
                <div>
                {(!codeSent) ? 
                (<div>
                    <div className="p-1 mb-2">
                        <b>Enter the email address you registered with.</b>
                    </div>
                    <div className="field">
                        <p className="control has-icons-left">
                            <input ref={inputUsername} type="email" defaultValue={email} onFocus={clearAllMessages} className="input is-medium" placeholder="Email"/>
                            <span class="icon is-left">
                                <FontAwesomeIcon icon={faEnvelope}/>
                            </span>
                        </p>
                    </div>
                    <div>
                        <button className="button is-primary" onClick={handleCodeResendClick}>Send code</button>
                    </div>
                </div>)
                :
                (<div>
                    <div className="p-1 mb-2">
                        <b>Enter your activation code and new password.</b>
                    </div>                
                    <div  className="field">                
                        <input type="text" className="input is-medium" ref={inputCode} onFocus={clearAllMessages} placeholder="Confirmation code"/>
                    </div>                
                    <div className="mb-2">
                        <PasswordNew getPasswordVal={setPasswordValue} clearError={clearAllMessages} />
                    </div>            
                    <div>
                        <button onClick={handleReset} onFocus={clearAllMessages}  className="button is-primary">Reset</button>
                    </div>                
                    <div className="mt-2">
                        Didn't receive a code? <a onClick={switchToResend} >Click here</a>
                    </div>
                </div>
                )}
                </div>
            ):(
            <div className="box">                               
                <div className="p-2"><b>Success! Your password has been changed.</b></div>
                <div className="p-2">Please login with your username and new password.</div>
                <div className="mt-2">
                    <button className="button is-primary" onClick={redirectToLogin}>Login</button>
                </div>
            </div>
            )}
        </div>
    );

}