import React,{useState,useRef} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faCheck} from '@fortawesome/free-solid-svg-icons';


export default function PasswordNew(props){
    const [validPwd, setValidPwd] = useState(false);
    const [validPwdCheckColor, setPwdCheckColor] = useState("");
    const [weakPwdClass, setWeakPwdClass] = useState("has-background-light");
    const [mediumPwdClass, setMediumPwdClass] = useState("has-background-light"); 
    const [strongPwdClass, setStrongPwdClass] = useState("has-background-light"); 
    const inputPwd = useRef();

    const validPassword = () => {
        //https://www.section.io/engineering-education/password-strength-checker-javascript/
  
        const pwdWeakRegex = new RegExp("(?=.{7,})"); // 7 length
        const pwdMedRegex = new RegExp("(?=.{7,})(?=.*[A-Za-z])(?=.*[0-9])"); // 7 length; digit
        const pwdStrRegex = new RegExp("(?=.{7,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9])"); // 7 length; Uppercase, Lower Case, digit, special charager        const pwdVal = NewPassword.current.value;
        const pwdVal = inputPwd.current.value;       
        const inactiveClass = "has-text-dark has-background-light";
        const weakClass = "has-text-white has-background-danger";
        const mediumClass = "has-text-white has-background-warning";
        const strongClass = "has-text-white has-background-primary";

        props.getPasswordVal(pwdVal);
  
        switch(true){
          case !pwdWeakRegex.test(pwdVal):
            setWeakPwdClass(inactiveClass);
            setMediumPwdClass(inactiveClass);
            setStrongPwdClass(inactiveClass);
            setValidPwd(false);
            break;
          case pwdWeakRegex.test(pwdVal) && !pwdMedRegex.test(pwdVal):
            setWeakPwdClass(weakClass);
            setMediumPwdClass(inactiveClass);
            setStrongPwdClass(inactiveClass);
            setValidPwd(true);
            setPwdCheckColor("red");
            break;          
          case pwdMedRegex.test(pwdVal) && !pwdStrRegex.test(pwdVal):
            setWeakPwdClass(weakClass);
            setMediumPwdClass(mediumClass);
            setStrongPwdClass(inactiveClass);
            setValidPwd(true);
            setPwdCheckColor("orange");
            break;
          case pwdStrRegex.test(pwdVal):
            setWeakPwdClass(weakClass);
            setMediumPwdClass(mediumClass);
            setStrongPwdClass(strongClass);
            setValidPwd(true);
            setPwdCheckColor("turquoise");
        }
        
      }

    return (
        <div>
            <div className="field">
                <p className="control has-icons-left  has-icons-right">
                    <input ref={inputPwd} type="password" onChange={validPassword} onFocus={props.clearerror} className="input is-medium" placeholder="Password 7+ characters"/>
                    <span class="icon is-left">
                        <FontAwesomeIcon icon={faLock}/>
                    </span>
                    <span class="icon is-right">
                      {validPwd && (
                        <FontAwesomeIcon icon={faCheck} color={validPwdCheckColor}/>
                      )}
                    </span>
                </p>                
            </div>
            {(inputPwd.current && inputPwd.current.value.length > 0) && (      
            <div className="box">     
              <div className="columns is-mobile">
                <div className="column is-size-7">
                  <b>Weak</b>
                </div>
                <div className="column is-size-7">
                  <b>Medium</b>
                </div>
                <div className="column is-size-7">
                  <b>Strong</b>
                </div>               
              </div>
              <div className="columns is-mobile">
                <div className={`column p-1 ${weakPwdClass}`}>
                 
                </div>
                <div className={`column p-1 ${mediumPwdClass}`}>
                  
                </div>
                <div className={`column p-1 ${strongPwdClass}`}>
                  
                </div>               
              </div>
            </div>
            )}
        </div>
    );

}