import React from 'react';
import {Bar} from 'react-chartjs-2';
import {isMobile} from 'react-device-detect';
import {Chart as ChartJS} from 'chart.js/auto';
/*
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );*/

  

export default function StackedChart(props){


    const options = {
        plugins: {
          title: {
            display: true,
            text: props.title,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: (isMobile ?  1: 2),
        lineTension: 1,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        }
      };
      
      const labels = props.labels; 
      
      const data = {
        labels,
        datasets: props.data,
      };

    return (
        <div>
        <Bar options={options}  data={data} />
      </div>
    );    
}