/*  export const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: [63,4,43],
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'Dataset 2',
        data: [53,24,33],
        backgroundColor: 'rgb(75, 192, 192)',
      },
      {
        label: 'Dataset 3',
        data: [23,44,3],
        backgroundColor: 'rgb(53, 162, 235)',
      },
    ],
  };*/

exports.chartDatasetsFromScenario = (scenario) => {

    const chartColours = ['#FF5757','#50c878','#ddf542','#f542e0','#f54272','#50c878','#FF6833','#33FFD4'];
    var colourIndex = 0;
    var dataSets = [];
    var labels = [];
    
    for(var i=0; i < scenario.scenario_option_ids.length; i++){
        labels.push(scenario.scenario_option_ids[i].opt_desc);
    }

    for(var n=0; n < scenario.scenario_dimension_ids.length; n++){

        var dimDataSet = [];
        for(var x=0; x < scenario.scenario_score_ids.length; x++){
            if (scenario.scenario_score_ids[x].scenario_dimension_id === scenario.scenario_dimension_ids[n]._id){
                dimDataSet.push(scenario.scenario_score_ids[x].rating * scenario.scenario_dimension_ids[n].dim_weight);
            }
        }

        dataSets.push({
            label: `${scenario.scenario_dimension_ids[n].dim_desc} (weight=${scenario.scenario_dimension_ids[n].dim_weight})`,
            data: dimDataSet,
            backgroundColor: chartColours[colourIndex]
        });

        colourIndex++;
        if (colourIndex >= chartColours.length){
            colourIndex = 0;
        }

    }
    
    return {
        labels,
        datasets: dataSets
    }

}

exports.chartDatasetsFromClassifications = (classifications) => {

  const chartColours = ['#f542e0','#FF6833','#33FFD4','#50c878','#FF5757','#50c878','#ddf542', '#f54272'];
  var colourIndex = 0;
  var dataSets = [];
  var labels = [];
  
  var dateCreated = [];
  var options = [];
  var reportCount = 1;
  for(var i=0; i < classifications.length; i++){
      if (!dateCreated.includes(classifications[i].date_created)){
        labels.push(reportCount);
        reportCount++;
        dateCreated.push(classifications[i].date_created);
      }

      if (!options.includes(classifications[i].scenario_option_description)){
        options.push(classifications[i].scenario_option_description);
      }
  }

  for(var n=0; n < options.length; n++){

      var dimDataSet = [];

      for(var y=0; y<dateCreated.length;y++)
      {
          var found = false;
          for(var x=0; x < classifications.length; x++){
              if (classifications[x].scenario_option_description === options[n] && classifications[x].date_created === dateCreated[y]){
                  dimDataSet.push(classifications[x].ranking);
                  found = true;
              }
          }

          if (!found){
            dimDataSet.push(options.length + 1);
          }
      }
    
      

      dataSets.push({
          label: options[n],
          data: dimDataSet,
          backgroundColor: chartColours[colourIndex],
          lineTension: 0
      });

      colourIndex++;
      if (colourIndex >= chartColours.length){
          colourIndex = 0;
      }

  }
  
  return {
      labels,
      datasets: dataSets
  }

}
