import react from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy} from '@fortawesome/free-solid-svg-icons';

export default function RankingList(props){


    return (<>
        <div>
            <div className="columns p-2 is-mobile notification is-link">
                <div className="column is-2"><b>Rank</b></div>
                <div className="column"><b>Description</b></div>
                <div className="column is-3"><b>Score*</b></div>
            </div>
            {
            props.rankingdata.map(rank => (
                <div key={rank._id}>
                    <div className="columns is-mobile" id={rank._id}>
                        <div className="column is-2">
                            <div className="level-item">
                            <b>{rank.ranking}</b>
                            {
                                (rank.ranking == 1) &&
                                <span className="ml-1"><FontAwesomeIcon icon={faTrophy} color="gold" /></span>
                            }
                            </div>
                        </div>
                        <div className="column">{rank.scenario_option_description}</div>
                        <div className="column is-3"><b>{rank.score}</b></div>
                    </div>
                    <hr/>
                </div>
            ))
            }
            <div><small>*Score is weighted</small></div>
        </div>
    </>)
}