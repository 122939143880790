import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock} from '@fortawesome/free-solid-svg-icons'


export default function ProcessingMessage(props){

    return (
        <>        
        {(props.processing) && (
        <article class="message is-warning">
            <div className="message-body">            
                <FontAwesomeIcon icon={faClock} /> Almost there...                        
            </div>
        </article>
        )} 
        </>        
    );
}