module.exports = {
    apiBaseUrl : {        
        biggieApi : "https://c537f9xeca.execute-api.ap-southeast-2.amazonaws.com/Stage", //"https://dq1x24bial.execute-api.ap-southeast-2.amazonaws.com/Stage",//"https://l5n6scay7b.execute-api.ap-southeast-2.amazonaws.com/Stage", //"http://localhost:5200",
        userApi : "https://i2prtubq3l.execute-api.ap-southeast-2.amazonaws.com/Stage", //"http://localhost:4000",
        logApi : " https://6pc39nu8e9.execute-api.ap-southeast-2.amazonaws.com/Stage",
        userApiKey : "LFPGzPce7F2EkD240CyDt2hbCv17BNnM7KjxT6Ya"
    },
    cognito : {
        userPoolId : "ap-southeast-2_Cb2q5l1ox",
        region : "ap-southeast-2"
    }
}