import ApiLog from "./apiLog";
import useAuth from "../auth/useAuth";


const logInfo = (context, msg) => {
    log("info",context, msg);
}

const logError = (context, msg) => {
    log("error",context,  msg)
}

const log = (type, context, msg) => {

    const apiLog = new ApiLog();

    const logData = {
        "id": Date.now().toString(),
        "type": type,
        "user": useAuth.getUserId(),
        "logcontext" : context,
        "message" : msg
    }

    apiLog.logsCreate(logData).catch(error => {
        console.log(error.response.data.message);
    });

}

export default {logError,logInfo}
