import React from "react";
import ChoiceReport from "../components/report/ChoiceReport";
import BreadcrumbNav from "../components/form/BreadcrumbNav";

export default function ResultSummary(){

    return (
        <div className="container  is-fluid has-text-left">
            <BreadcrumbNav page='result'/>
            <div className="has-text-left">          
              <ChoiceReport/>    
            </div>                       
        </div>
    )

}