import React from "react";

export default function MessageList(props) {
    
    return (
            (props.messages.length > 0) && (
                <div className="notification is-info">
                    {props.messages.map(msg => (
                        <li key={msg}>
                        {msg}
                        </li>
                    ))}      
                </div>
            )    
         
    );

}