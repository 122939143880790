import React from "react";

export default function BoxedLayout(props){

    return (
        <div className="container">
            <div>
                &nbsp;
            </div>
            <div class="columns">
                <div class="column"></div>
                <div class="column is-two-thirds">
                <div className="box">
                    {props.content}
                </div>
                </div>
                <div class="column"></div>
            </div>
        
        </div>
    );
}