import react,{useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import FloatingBottomBar from "./FloatingBottomBar";
import { dom } from "@fortawesome/fontawesome-svg-core";
import constants from "../common/constants";

export default function CookieMessage(){
    const [agreeToCookies, setAgreement] = useState(false);

    useEffect(() => {
        if (localStorage.getItem(constants.storage.agreeCookies) == 'true'){
            setAgreement(true);
        }
    },[]);

    const setAgreementHandleClick = () => {
        localStorage.setItem(constants.storage.agreeCookies,'true');
        setAgreement(true);
    }

    return(<>
    {
        (!agreeToCookies) && 
        (
        <FloatingBottomBar message={
                <div>
                    This website stores data such as cookies to enable essential site functionality, as well as marketing, personalization, and analytics. By remaining on this website you indicate your consent. Our policy can be found <a href="/termsandconditions#cookies" style={{color:"turquoise"}}>here</a>.
                </div>
            }
            buttontext={'I agree'}    
            handleClick={setAgreementHandleClick}
        />
        )
    }
    
    </>)

}