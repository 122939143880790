import React from "react";
import AssessmentList from "../components/assess/AssessList";
import BreadcrumbNav from "../components/form/BreadcrumbNav";


export default function Assess(){

    return (
        <div className="container  is-fluid has-text-left">            
            <BreadcrumbNav page='assess' />
            <div className="has-text-left">                         
            <AssessmentList/>
            </div>     
        </div>
    )

}