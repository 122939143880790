
import constants from "../common/constants";
import jwt from 'jwt-decode';
import ApiUser from "../common/apiUser";
import commonFunctions from "../common/commonFunctions";

const loginAnon = async () => {

    const apiUser = new ApiUser();

    const userCredentials = {
        username: constants.anon.username,
        password:""
    }

    await apiUser.userLogin(userCredentials)
    .then(response => {

        credentialsTolocalStorage(response, constants.loggedInStatus.loggedInAnon);
        
    }).catch(err => {        
        console.error(JSON.stringify(err));
    });

}

const scenarioSession = async () => {

    const status = await loginStatus();
    switch (status) {
        case constants.loggedInStatus.loggedOut:
        case constants.loggedInStatus.confirm:        
            await loginAnon();
            break;
        default:
            //already logged in
            console.info("Scenario Session usign existing session");
            break;
    }

}

const credentialsTolocalStorage = (response, loginStatus) => {

    localStorage.setItem(constants.storage.user, response.data.user); 
    localStorage.setItem(constants.storage.accessToken, response.data.access_token);
    localStorage.setItem(constants.storage.idToken, response.data.id_token);
    localStorage.setItem(constants.storage.refreshToken, response.data.refresh_token);
    localStorage.setItem(constants.storage.loggedIn, loginStatus);
    localStorage.setItem(constants.storage.expiry, response.data.expiry); //required any more?
}

const getUserId = () => {
   try{
       
        const email = getIdTokenAttribute("email"); 
        if (email == null || email.length == 0){
            return constants.anon.username;
        }else{
            return email;
        }

    }catch{
    return constants.anon.username;
   }    
}



const refreshToken =  async() => {
    

    const apiUser = new ApiUser();

    if (localStorage.getItem(constants.storage.idToken) != null){

        const expiry = getIdTokenAttribute("exp");
        if (expiry * 1000 < Date.now()) {
            //try and get new token with 
            const refreshToken = {
                refreshtoken: localStorage.getItem(constants.storage.refreshToken),
                username : localStorage.getItem(constants.storage.user)
            }

            try{            
                const response = await apiUser.userRefreshToken(refreshToken);
                localStorage.setItem(constants.storage.accessToken, response.data.access_token);
                localStorage.setItem(constants.storage.idToken, response.data.id_token);
                localStorage.setItem(constants.storage.refreshToken, response.data.refresh_token);                
                localStorage.setItem(constants.storage.expiry, response.data.expiry);
                console.log(response.data);
            }catch(error){
                console.log(error);
                commonFunctions.clearSessionStorage();
                
            }

        }

    }
}

const fullTokenValidation = async () => {

    const apiUser = new ApiUser();
    var valid = false;

    const tokenValidation = {
        token: localStorage.getItem(constants.storage.accessToken),
        username: localStorage.getItem(constants.storage.user)
    }

    try{            
        const response = await apiUser.userValidateToken(tokenValidation);
        valid = response.data.valid;            
        if (valid){
            localStorage.setItem(constants.storage.loggedIn, constants.loggedInStatus.loggedIn);
        }else{
            if (localStorage.getItem(constants.storage.loggedIn) != constants.loggedInStatus.confirm){
                localStorage.setItem(constants.storage.loggedIn, constants.loggedInStatus.loggedOut);
            }
        }
    }catch(error){
        console.log(error);
    }

}



const loginStatus = async () => {

    const currentStatus = localStorage.getItem(constants.storage.loggedIn);
    const token = localStorage.getItem(constants.storage.idToken);
    var status = constants.loggedInStatus.loggedOut;

    if (currentStatus != null){
        if (currentStatus == constants.loggedInStatus.confirm){
            status = constants.loggedInStatus.confirm;
        }else{
            if (token != null){
                const expiry = getIdTokenAttribute("exp");
                if (expiry * 1000 < Date.now()) {
                    //status = constants.loggedInStatus.expired;
                    // call refresh token
                    try{
                        await refreshToken();
                        status = constants.loggedInStatus.loggedIn
                    }catch(err){
                        status = constants.loggedInStatus.expired;
                        console.error(`Error refreshing token ${JSON.stringify(err)}`);
                    }
                }else{
                    const group = getIdTokenAttribute("cognito:groups");
        
                    switch (group) {
                        case "anon":
                            status = constants.loggedInStatus.loggedInAnon;        
                            break;
                        default:
                            status = constants.loggedInStatus.loggedIn;
                            break;
                    }
                }
            }

        }

    }
    localStorage.setItem(constants.storage.loggedIn,status);
    return status;

}

const getIdTokenAttribute = (attr) => {

    var val ="";
    const token = localStorage.getItem(constants.storage.idToken);

    if (token != null){
        const idToken = jwt(token);
        try{
 
            switch (attr) {
                case "cognito:groups":
                    const groupArr = idToken["cognito:groups"];
                    if (groupArr != null && groupArr.length > 0){
                        return groupArr[0];
                    }else{
                        return "";
                    }                    
                default:
                    return idToken[attr];                    
            }

        }catch(err){
            console.error(JSON.stringify(err));
        }
    }else{
        return "";
    }
    
}


export default {fullTokenValidation, loginStatus, loginAnon, credentialsTolocalStorage, scenarioSession, getIdTokenAttribute, getUserId }


/*
const isAuthenticated =  async() => {
    

    const apiUser = new ApiUser();
    var valid = false;

    if (localStorage.getItem(constants.storage.idToken) != null){

        const expiry = getIdTokenAttribute("exp");
        if (expiry * 1000 < Date.now()) {
            //try and get new token with 
            const refreshToken = {
                refreshtoken: localStorage.getItem(constants.storage.refreshToken),
                username : localStorage.getItem(constants.storage.user)
            }

            try{            
                const response = await apiUser.userCognitoRefreshToken(refreshToken);
                localStorage.setItem(constants.storage.accessToken, response.data.access_token);
                localStorage.setItem(constants.storage.idToken, response.data.id_token);
                localStorage.setItem(constants.storage.refreshToken, response.data.refresh_token);                
                localStorage.setItem(constants.storage.expiry, response.data.expiry);
                console.log(response.data);
            }catch(error){
                console.log(error);
            }

        }

        const tokenValidation = {
            token: localStorage.getItem(constants.storage.accessToken),
            username: localStorage.getItem(constants.storage.user)
        }

        try{            
            const response = await apiUser.userCognitoValidateToken(tokenValidation);
            valid = response.data.valid;            
            if (valid){
                localStorage.setItem(constants.storage.loggedIn, constants.loggedInStatus.loggedIn);
            }else{
                if (localStorage.getItem(constants.storage.loggedIn) != constants.loggedInStatus.confirm){
                    localStorage.setItem(constants.storage.loggedIn, constants.loggedInStatus.loggedOut);
                }
            }
        }catch(error){
            console.log(error);
        }

    }
    //loginStatus();
    return valid;
}
*/
