const ApiLog = require('./apiLog');
const constants = require('./constants');

const emailValidation = (email) => {

    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(regex.test(email) === false){
        return false;
    }
    return true;
}

const passwordValidation = (pwdVal) => {
    const pwdWeakRegex = new RegExp("(?=.{7,})"); // 7 length
    
    return pwdWeakRegex.test(pwdVal);

}
function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

const formatDate = (theDate) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const date = new Date(theDate);

    return `${monthNames[date.getMonth()]} ${padTo2Digits(date.getDate())}, ${date.getFullYear()}`;
}

const clearSessionStorage = () => {
    localStorage.removeItem(constants.storage.idToken);
    localStorage.removeItem(constants.storage.accessToken);
    localStorage.removeItem(constants.storage.refreshToken);
    localStorage.removeItem(constants.storage.expiry);
    localStorage.removeItem(constants.storage.user);
    localStorage.removeItem(constants.storage.scenarioId);
    localStorage.removeItem(constants.storage.loggedIn);
}

module.exports = {emailValidation, passwordValidation, formatDate, clearSessionStorage};
