import React from 'react';
import {Line} from 'react-chartjs-2';
import {isMobile} from 'react-device-detect';
import {Chart as ChartJS} from 'chart.js/auto';
/*
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );*/

  

export default function LineChart(props){

    const options = {
        plugins: {
          title: {
            display: true,
            text: props.title,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: (isMobile ?  1: 2),
        lineTension: 1,
        bezierCurve: false,
        scales: {
            x: {
              title: {
                display: true,
                text: (props.xtitle)
              }
            },
            y: {
              title: {
                display: true,
                text: (props.ytitle)
              },
              min: 0,
              max:(props.data.length + 1),
              ticks: {
                // forces step size to be 50 units
                stepSize: 1
              },
              reverse: true
            }
        }
      };
      
      const labels = props.labels; 
      
      const data = {
        labels,
        datasets: props.data,
      };

    return (
        <div>
        <Line options={options}  data={data} />
      </div>
    );    
}