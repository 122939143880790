import React from "react";


export default function Advice(){

    return (
        <div className="container  is-fluid has-text-left">            
        <h1 className="is-1">Get some advice</h1>
        </div>
    )

}