import React from "react";
import ScenarioList from '../components/scenarios/ScenarioList';


export default function Dashboard(){

    return (
        <div className="container  is-fluid has-text-left">                                            
            <ScenarioList/>
        </div>
    );
}