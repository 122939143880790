import React from "react";
import BoxedLayout from "../../parts/layout/BoxedLayout";
import ResetPasswordForm from "../../components/account/ResetPasswordForm";

export default function ResetPassword() {
    return(
        <BoxedLayout content=
        {
            <>
            <h1 className="title">Reset Password</h1>
            <ResetPasswordForm/>
            </>
        }/>
    );
}
