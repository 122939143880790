import React,{useRef} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus} from '@fortawesome/free-solid-svg-icons'

export default function InputAdd(props) {
  
    const inputAdd = useRef(null);

    const handleAdd = () => {
        props.addValue(inputAdd.current.value); 
        inputAdd.current.value = "";
        //window.location.reload(); 
    }

    return (
    <div className="p-2">
        <div className="level-item level-left">
            <div className="field has-addons">
                <p className="control">
                    <input className="input" ref={inputAdd} type="text" placeholder="Add an entry"/>
                </p>
                <p className="control">
                    <button onClick={handleAdd} className="button is-primary">
                        <FontAwesomeIcon icon={faPlus} color="white"/>
                    </button>                   
                </p>
            </div>
        </div>        
    </div>
    );
}