import React, { useRef, useEffect, useState, useReducer } from "react";
import RecallAttempt from "./RecallAttempt";

function GetRandomArray(count){
    let recallList = [];

    for (var i=0; i < count; i++){
        const randomNo = Math.floor(Math.random() * 9) + 1;
        if (!recallList.includes(randomNo)){
            recallList[i] = randomNo;
        }else{
            i--;
        }
    }
    return recallList;
}

export default function Recall(props) {
    const [pause, setPause] = useState(true);
    const [resultReady, setResultReady] = useState(false);
  
    let intervalRef = useRef();
  
    const IndexSetter = (index) => {
      if (index > props.count ){
        clearInterval(intervalRef.current);
        setResultReady(true);
        return -1;
      }else{
        return index + 1;
      }
    }
  
    const [index, setIndex] = useReducer(
      IndexSetter,
      -1
    )
  
    const [randomNumbers, setRan] = useState([]);
  
    const getRandomNum = (index) => {
      
      if (randomNumbers.length > 0 && index >= 0){
        return randomNumbers[index];
      }else{
        return ""
      } 
    }
  
    const nextNumber = () => {
        setIndex();
    };
  
    useEffect(() => {
        setPause(true);
        setRan(GetRandomArray(props.count));
        return () => clearInterval(intervalRef.current);
      
    }, []);
    
    const beginClick = () => {
        intervalRef.current = setInterval(nextNumber, 1000);
        setPause(false);
    };
    
    return (
      <div>
        <h1 className="title">Remember the numbers</h1>
        <section class="hero is-primary">
        <div class="hero-body">
            <p class="title">
            &nbsp;{!pause ? getRandomNum (index) : "Get Ready"}
            </p>
            
        </div>
        </section>
        {resultReady && 
          <RecallAttempt numbers={randomNumbers.join('')}/>
        }
        {(index === -1 && pause) && 
            <button className="button" onClick={beginClick}>{pause ? "Run" : "Pause"}</button>
        }
      </div>
    );
  }
  