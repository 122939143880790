import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';

function getColor(weight) {
    // Define a color scale from cool to warm
    const colors = [  'white',
    'lightgreen', 
    'green',
    'lime',
    'yellow',
    'orange',
    'darkorange',
    'orangered',
    'red',
    'firebrick',
    'darkred'];

    return colors[Math.round(weight)];
}

function App() {
  // Sample problem data
  const problemsData = {
    "Everyday Problems": [
      {"problem": "Transport issues / Traffic Jams", "weight": 2, "control": 9},
      {"problem": "Bad Weather", "weight": 2, "control": 9},
      {"problem": "Long Wait Times", "weight": 3, "control": 9},
      {"problem": "Miscommunications", "weight": 2, "control": 2},
      {"problem": "Technical Issues", "weight": 4, "control": 6},
      {"problem": "Canceled Plans", "weight": 3, "control": 2},
      {"problem": "Household Chores", "weight": 3, "control": 2},
      {"problem": "Work Dissastisfaction", "weight": 3, "control": 3},
      {"problem": "Workplace Conflict", "weight": 4, "control": 6},
      {"problem": "Unpleasant Tasks", "weight": 3, "control": 6},
      {"problem": "Health Issues", "weight": 5, "control": 6},
      {"problem": "Education Expenses", "weight": 4, "control": 6},                  
      {"problem": "Car Troubles", "weight": 4, "control": 6},
      {"problem": "Aging Parents", "weight": 5, "control": 6},
      {"problem": "Environmental Concerns", "weight": 3, "control": 6},            
      {"problem": "Career Advancement", "weight": 4, "control": 6},
      {"problem": "Global Issue Concerns", "weight": 2, "control": 10},
      {"problem": "Children Worries", "weight": 4, "control": 6}
    ],
    "Bigger Non-Life-Threatening Problems": [
      {"problem": "Financial Debt", "weight": 6, "control": 6},
      {"problem": "Job Loss", "weight": 7, "control": 6},
      {"problem": "Relationship Issues", "weight": 5, "control": 6},
      {"problem": "Divorce", "weight": 7, "control": 7},
      {"problem": "Legal Troubles", "weight": 7, "control": 6},
      {"problem": "Major Home Repairs", "weight": 6, "control": 6},
      {"problem": "Education Expenses", "weight": 7, "control": 6},            
      {"problem": "Business Challenges", "weight": 7, "control": 6},
      {"problem": "Mental Health Issues", "weight": 6, "control": 6},      
    ],
    "More Severe Problems": [
      {"problem": "Serious Illness or Terminal Disease", "weight": 9, "control": 6},
      {"problem": "Natural Disasters", "weight": 10, "control": 10},
      {"problem": "Child Loss", "weight": 10, "control": 10},
      {"problem": "War and Conflict", "weight": 10, "control": 10},
      {"problem": "Severe Poverty", "weight": 9, "control": 6},
      {"problem": "Addiction", "weight": 8, "control": 6},
      {"problem": "Homelessness", "weight": 8, "control": 6},
      {"problem": "Domestic Violence", "weight": 9, "control": 6},
      {"problem": "Human Trafficking", "weight": 10, "control": 10},
      {"problem": "Child Abuse and Neglect", "weight": 9, "control": 10},
      {"problem": "Political Oppression", "weight": 9, "control": 10},
      {"problem": "Refugee Status", "weight": 10, "control": 10},
      {"problem": "Genocide", "weight": 10, "control": 10},
      {"problem": "Human Rights Violations", "weight": 9, "control": 10},
      {"problem": "Environmental Disasters", "weight": 10, "control": 10},
      {"problem": "Infectious Disease Epidemics", "weight": 10, "control": 6},
      {"problem": "Civil Unrest and Revolutions", "weight": 9, "control": 10},
      {"problem": "Terrorism", "weight": 9, "control": 10},
      {"problem": "Chronic Health Conditions", "weight": 8, "control": 6}
    ]
  };
  
  const [selectedProblems, setSelectedProblems] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [averageScore, setAverageScore] = useState(0);

  const isProblemSelected = (problem) => {
    return selectedProblems.some((selectedProblem) => selectedProblem.problem === problem.problem);
  };

  const handleProblemSelect = (problem) => {
    if (!isProblemSelected(problem)) {
      // Only add the problem if it's not already selected
      setSelectedProblems([...selectedProblems, problem]);
    } else {
      // Deselect the problem if it's already selected
      const updatedSelectedProblems = selectedProblems.filter(
        (selectedProblem) => selectedProblem.problem !== problem.problem
      );
      setSelectedProblems(updatedSelectedProblems);
    }
  };

  useEffect(() => {
    // Calculate total score
    const newTotalScore = selectedProblems.reduce(
      (total, selectedProblem) => total + selectedProblem.weight,
      0
    );
    setTotalScore(newTotalScore);

    // Calculate average score
    const newAverageScore =
      selectedProblems.length > 0 ? newTotalScore / selectedProblems.length : 0;
    setAverageScore(newAverageScore);
  }, [selectedProblems]);

  // Create an array of colors for each selected problem
  const problemColors = selectedProblems.map((problem) => getColor(problem.weight));

  // Chart setup data with custom colors
  const chartData = {
    labels: selectedProblems.map((problem) => problem.problem),
    datasets: [
      {
        label: 'Problem Weight',
        data: selectedProblems.map((problem) => problem.weight),
        backgroundColor: problemColors, // Use custom colors
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Average Score',
        data: selectedProblems.map(() => averageScore),
        type: 'line',
        fill: false,
        //borderColor: 'rgba(255, 99, 132, 1)',
        borderColor: getColor(averageScore),
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max:10,
        title: {
          display: true,
          text: 'Problem Weight',
        },
      },
    },
  };

  return (
    <div className="App">
      <h1>Select Your Problems</h1>
      {Object.keys(problemsData).map((category) => (
        <div key={category}>
          
          <div className='box m-2'>
            <h2 className='title is-5'>{category}:</h2>
            {problemsData[category].map((problem) => (
              
              <>
                {isProblemSelected(problem) ? (
                  <button className='button is-danger m-2' onClick={() => handleProblemSelect(problem)}>{problem.problem} ({problem.weight})</button>
                ) : (
                  <button className='button is-primary m-2' onClick={() => handleProblemSelect(problem)}>{problem.problem} ({problem.weight})</button>
                )}
              </>  
            ))}
          </div>
        </div>
      ))}
      <div>
        <strong>Total Score:</strong> {totalScore}
      </div>
      <div>
        <strong>Average Score:</strong> {averageScore}
      </div>
      {selectedProblems.length > 0 && (
        <div>
          <h2>Problem Scores Chart</h2>
          <div style={{ height: '300px', width: '500px' }}>
            <Bar data={chartData} options={chartOptions} />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;