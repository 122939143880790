import React from "react";
import ChoiceList from "../components/choice/ChoiceList";
import BreadcrumbNav from "../components/form/BreadcrumbNav";

export default function Choices(){

    return (
        <div className="container  is-fluid has-text-left">           
            <div className="has-text-left">   
            <BreadcrumbNav page='choices' />
            <div className="notification"><b>Step 1 of 3:</b> Update the choices below. Click the tick button if changing an entry to confirm.</div>                  
            <ChoiceList/>
            </div>       
        </div>
    )

}