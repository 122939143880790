import React, {  useEffect, useState } from "react";
import {useParams, useNavigate, useRoutes} from 'react-router-dom';
import ApiBiggie from "../../common/apiBiggie";
import InputEditable from "../form/InputEditable";
import InputAddable from "../form/InputAddable";
import InputDeletable from "../form/InputDeletable";
import ErrorList from "../form/ErrorList";
import ProcessingMessage from "../form/ProcessingMessage";
import PageNav from "../form/PageNav";
import TitleEditable from "../form/TitleEditable";
import LoadingPage from "../../parts/LoadingPage";
import constants from "../../common/constants";
import log from '../../common/logFunctions';

export default function ChoiceList(){
    const [errorMessages, setErrorMessage] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [title, setTitle] = useState();
    let {id} = useParams();
    const navigate = useNavigate();
    const apiBiggie = new ApiBiggie();
    
    useEffect(async () => {                
        try{            
            await apiBiggie.initialize();
            const result = await apiBiggie.ScenarioGetById(id)
            
            setItems(result.data.scenario_option_ids);
            setTitle(result.data.scenario_desc);
            
        }catch(err){            
            setErrorMessage([constants.error.generalMessage]);
            log.logError("ChoiceList.useEffect",JSON.stringify(err));
        }
        setIsLoaded(true);
    }, [])

    const updateChoice = async (optionId,val) => {
      if (val != null && val.length > 0){
        setErrorMessage([]); 
        const updateOption = {          
            opt_desc:val,
            default_opt_rating: 5
        };

        try{
          setProcessing(true);
          await apiBiggie.initialize();
          const response = await apiBiggie.ScenarioOptionsUpdate(id,optionId, updateOption);
          console.log(`Option details ${JSON.stringify(response)}`);
          setProcessing(false);
          //apiBiggie.OptionsUpdate(optionId, updateOption);
          
        }catch(err){            
          setErrorMessage([constants.error.generalMessage]);
          log.logError("ChoiceList.updateChoice",JSON.stringify(err));
        }
      }else{
        setErrorMessage(['The value entered is empty']); 
      }
      
    }

    const addChoice = async (val) => {
      if (val != null && val.length > 0){
        setErrorMessage([]); 
        const newOption = {              
            default_opt_rating: 5,
            opt_desc: val
        }

        try{
          setProcessing(true);
          await apiBiggie.initialize();
          const response = await apiBiggie.ScenarioOptionsCreate(id,newOption);
          setItems([response.data,...items]);
          setProcessing(false);
          console.log(`Option details ${JSON.stringify(response)}`);
        }catch(err){            
          setErrorMessage([constants.error.generalMessage]);
          log.logError("ChoiceList.addChoice",JSON.stringify(err));
        }
      }else{
        setErrorMessage(['The value entered is empty']); 
      }
    }

    const deleteChoice = (optionId) => {
      setErrorMessage([]); 
      try{
        apiBiggie.ScenarioOptionsDelete(id,optionId);
        const revisedItemList = [];
        items.forEach(oId => {
          if (oId._id != optionId){
            revisedItemList.push(oId);
          }
        
        });
        setItems(revisedItemList);
      }catch(err){            
        setErrorMessage([constants.error.generalMessage]);
        log.logError("ChoiceList.deleteChoice",JSON.stringify(err));
      }
    }

    const handleNextClick = () => {
      navigate(`/criteria/${id}`);
    }

    const updateTitle = () => {

    }
  
    if (!isLoaded) {
      return <div><LoadingPage/></div>;
    } else {
      return (
        <>
         
          <div className="box">    
            <ErrorList errors={errorMessages}/>        
            <ProcessingMessage processing={processing}/>
            <div className="title ml-2">
            <TitleEditable title={title} id={id} setErrorMessage={setErrorMessage}/>
            </div>    
            <div>
              <InputAddable addValue={addChoice} navRefresh={'/choices/' + id}/>
            </div>
            <hr/>            
            {items.map(option => (
              <div key={option._id}>
                <div>
                
                  <InputDeletable id={option._id} desc={option.opt_desc} deleteValue={deleteChoice} />
                </div>
                <hr/>
              </div>
            ))}        
          </div>
          <PageNav nextClick={handleNextClick} backClick={() => navigate('/')}/>
        </>
      );
    }
}