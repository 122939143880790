import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom';
import {faHospitalUser,   faMoneyBillWave, faHammer, faSitemap, faUserMd, faArrowRight, faRunning, faBriefcase, faUserAstronaut, faListOl, faUserNinja,faUserGraduate, faChartBar, faGavel, faStar} from '@fortawesome/free-solid-svg-icons'
import ChoiceNew from "../components/choice/ChoiceNew";
import constants from "../common/constants";
import logoLight from '../images/gmt-bl_t_light.png'
import GMTLogo from "../parts/GMTLogo";

export default function Home(){

    return (
    <>    
    <div className="columns mt-4 ">
      <div className="column">
      <div className="ml-1">
        <GMTLogo size={1}/>
        <div  className="mt-2">        
        
        <h1 className="title is-3 has-text-weight-light">Your Career Decision Guide</h1>  
        </div>
      </div>
      </div>
      <div className="column" >
        
      </div>
    </div> 
    <div className="ml-2">
    
      <h3 className="title is-5 has-text-weight-light">
      Important{' '}
      <strong className="has-text-weight-bold">career decisions</strong> are hard to make. 
      Our user-friendly automated flow will walk you through new{' '}
      <strong className="has-text-weight-bold">career paths</strong>, 
      job transitions, and <strong className="has-text-weight-bold">academic pursuits</strong>, 
      empowering your choices with support and insights.
      </h3>      
  </div>
    <div className="columns mt-4">
      <div className="column">
        <div className="">
          <div className="p-4">
            <FontAwesomeIcon icon={faUserAstronaut} size="3x" className="has-text-link"/>
          </div>
          <div className="has-text-left ml-4">
            <h2 className="title is-4">Thinking about a new career?</h2>
            <p className="subtitle  has-text-grey">Exciting times, what are the options?</p>  
          </div>
          <div className="p-4">             
            <ChoiceNew color={'is-primary'} linkname={"Career decision"} category={constants.biggie.category.career}/>
          </div>
        </div> 
      </div>
      <div className="column">
        <div className="">
          <div className="p-4">                  
            <FontAwesomeIcon icon={faBriefcase} size="3x" className="has-text-link"/>
            </div>
            <div className="has-text-left ml-4">
              <h2 className="title is-4">Thinking about changing jobs?</h2>
              <p className="subtitle  has-text-grey">Great, let's help you choose!</p>
            </div>
            <div className="p-4">
              <ChoiceNew color={'is-primary'} linkname={"Job decision"} category={constants.biggie.category.jobchange}/>
            </div>
          </div>
      </div>
      <div className="column">
        <div className="">
            <div className="p-4">
              <FontAwesomeIcon icon={faUserGraduate} className="has-text-link"  size="3x"/>
            </div>
            <div className="has-text-left ml-4">
              <h2 className="title is-4">Thinking about what to study?</h2>
              <p className="subtitle has-text-grey">The world is your oyster, let's begin.</p>  
            </div>
            <div className="p-4">             
              <ChoiceNew color={'is-primary'} linkname={"Study decision"} category={constants.biggie.category.education}/>
            </div> 
        </div>
      </div>
    </div>
   
    <div className="columns">
      <div className="column">
        <div className="p-4">
            <article className="tile is-child">
            <p className="title is-4">How it works</p>                        
              <div className="columns is-mobile">
                <div className="column has-text-grey is-size-5 is-1"><b>1.</b></div>
                <div className="column has-text-grey is-size-5">List Your Career Options</div>
                <div className="column is-2"><FontAwesomeIcon icon={faListOl}  className="has-text-primary" size="2x"/></div>                          
              </div>
            </article>  
            <article className="tile is-child ">
            <div className="columns is-mobile">
                <div className="column has-text-grey is-size-5 is-1"><b>2.</b></div>
                <div className="column has-text-grey is-size-5">Define Your Criteria and Priorities</div> 
                <div className="column is-2"><FontAwesomeIcon icon={faStar}  className="has-text-primary" size="2x"/></div>
              </div>
            </article>
            <article className="tile is-child ">
            <div className="columns is-mobile">
                <div className="column has-text-grey is-size-5 is-1"><b>3.</b></div>
                <div className="column has-text-grey is-size-5">Evaluate Your Choices</div> 
                <div className="column is-2"><FontAwesomeIcon icon={faGavel} className="has-text-primary" size="2x"/></div>
              </div>
            </article>
            <article className="tile is-child ">
            <div className="columns is-mobile">
                <div className="column has-text-grey is-size-5 is-1"><b>4.</b></div>
                <div className="column has-text-grey is-size-5">Access Your Personalized Thought Report</div> 
                <div className="column is-2"><FontAwesomeIcon icon={faChartBar}  className="has-text-primary" size="2x"/></div>
              </div>
            </article>
          </div>
      </div>
      <div className="column">
        <div className="p-4">
          <div className="p-4  has-text-priamry">
            <FontAwesomeIcon icon={faRunning}  className="has-text-link" size="3x"/>....... 
            <FontAwesomeIcon icon={faMoneyBillWave} className="has-text-link" size="3x"/>
          </div>
          <div className="has-text-left ml-4">
            <p className="title is-5">Track your career choices</p>                        
            <p className="subtitle  has-text-grey">Track and learn from your historical <i>Thought Reports</i></p>  
          </div>
          <div className="p-4">             
            <Link to="/register" className="button is-primary">Register to save</Link>
          </div>
        </div>   
      </div> 
      <div className="column">
        <div className="p-4">
          <div className="p-4  has-text-primary">
          <FontAwesomeIcon icon={faHammer} className="has-text-link"  size="3x"/>
          </div>
          <div className="has-text-left ml-4">
            <p className="title  is-5">Build your own</p>                        
            <p className="subtitle  has-text-grey">Go freestyle and build your own template, list your options and criteria.</p>  
          </div>
          <div className="p-4">             
            <ChoiceNew color={'is-primary'} linkname={"Build your own decision"} category={constants.biggie.category.other}/>
          </div>
        </div>   
      </div>     
    </div>
    
      </>  
    )

}